import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  MessageBox,
  Dropdown,
  SearchInput,
  TextInput,
} from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getSearchParams } from "../../utils";

const BuyerWalletTransactions = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [walletType, setWalletType] = useState("");
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/reports/buyer-wallet-transactions?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}&fromDate=${fromDate}&toDate=${toDate}&walletType=${walletType}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["buyer_wallet_transactions", paginationData],
    queryFn: readData,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setSearchQuery("");
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
    setWalletType("");
    refetch();
  };

  const exportData = async () => {
    exportFromJSON({
      data: data.data,
      fileName: "buyer_wallet_transactions",
      exportType: exportFromJSON.types.csv,
    });
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Buyer Wallet Transactions"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
      showExportButton={true}
      onClickExport={exportData}
    />
  );

  const walletTypeOptions = [
    {
      name: "-- SELECT WALLET TYPE --",
      value: "",
    },
    {
      name: "GST",
      value: "GST",
    },
    {
      name: "Non-GST",
      value: "Non-GST",
    },
  ];

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <SearchInput
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Search transaction id"
                disabled={isLoading}
              />
              {nonGstWalletStatus === "Active" && (
                <Dropdown
                  value={walletType}
                  onChange={setWalletType}
                  placeholder="Wallet Type"
                  options={walletTypeOptions}
                  disabled={isLoading}
                />
              )}
              <TextInput
                type="date"
                value={fromDate}
                onChange={setFromDate}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={toDate}
                onChange={setToDate}
                placeholder="To Date"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Transactions Id",
                  dataSelector: "transactionsId",
                  dataType: "string",
                  cellrenderer: (value) => value ?? "N/A",
                },
                {
                  title: "Wallet Type",
                  dataSelector: "walletType",
                  dataType: "string",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "Transaction Type",
                  dataSelector: "transactionType",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  cellrenderer: (value, item) => (
                    <span
                      style={{
                        color:
                          item.transactionType === "Credit" ? "green" : "red",
                      }}
                    >
                      {formatCurrency(value)}
                    </span>
                  ),
                },
                {
                  title: "Balance",
                  dataSelector: "balance",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Date & Time",
                  dataSelector: "createdOn",
                  dataType: "string",
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(BuyerWalletTransactions);
