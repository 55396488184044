import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { withAppHOC } from "../../hoc";
import { StyledContentContainer } from "../../components/styled";
import { MessageBox } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";

const StyledFirmDetailsContainer = styled.section`
  h1 {
    font-size: 24px;
    color: #000;
    margin: 0px;
    padding: 0px;
    margin-bottom: 20px;
  }
  address {
    font-size: 16px;
    color: #000;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 16px;
    color: #000;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 20px;
  }
`;

const FirmDetails = () => {
  const readData = async () => {
    try {
      const response = await API.get(`/meta/firm-details`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["firm_details"],
    queryFn: readData,
  });

  const renderPageHeading = () => <PageHeader title="Support & Firm Details" />;

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledFirmDetailsContainer>
          <h1>{data?.data?.firmName}</h1>
          <address>{data?.data?.firmAddress}</address>
          <h2>
            <b>GST:</b> {data?.data?.gstNumber}
          </h2>
          <h2>
            <b>Support Phone Number:</b> {data?.data?.supportNumber}
          </h2>
          <h2>
            <b>Support Email Address:</b> {data?.data?.supportEmail}
          </h2>
        </StyledFirmDetailsContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(FirmDetails);
