import React from "react";
import styled from "styled-components";

const StyledMarquee = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 10px 0px;
  background-color: #ffbf00;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
`;

const ScrollMessage = ({ scrollMessage }) => {
  return (
    scrollMessage && (
      <StyledMarquee direction="left">
        <marquee>{scrollMessage}</marquee>
      </StyledMarquee>
    )
  );
};

export default ScrollMessage;
