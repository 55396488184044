import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledActionItemButtons,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledEditItemButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, TextInput } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getSearchParams } from "../../utils";

const BuyerInvoicesList = () => {
  const [filters, setFilters] = useState({
    date: "",
    year: "",
    month: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const handleFilterChange = (key, value) => {
    const splitValue = value && value.split("-");

    setFilters((prev) => ({
      ...prev,
      [key]: value,
      year: splitValue[0] || "",
      month: splitValue[1] || "",
    }));
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/invoicing/buyer?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&year=${appliedFilters.year}&month=${appliedFilters.month}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["buyer_invoices", paginationData, appliedFilters],
    queryFn: readData,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    setAppliedFilters(filters);
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFilters({
      date: "",
      year: "",
      month: "",
    });
    setAppliedFilters({
      date: "",
      year: "",
      month: "",
    });
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Buyer Invoices"
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <TextInput
                type="month"
                value={filters.date}
                onChange={(value) => handleFilterChange("date", value)}
                placeholder="Year & Month"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Year",
                  dataSelector: "year",
                  dataType: "string",
                },
                {
                  title: "Month",
                  dataSelector: "month",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  cellrenderer: (value) =>
                    value ? formatCurrency(value) : "N/A",
                },
                {
                  title: "",
                  dataSelector: "invoiceFile",
                  canSort: false,
                  isSticky: "Yes",
                  stickyAlign: "right",
                  isDummy: "Yes",
                  textAlign: "center",
                  width: "max-content",
                  cellrenderer: (value) =>
                    value && (
                      <StyledActionItemButtons>
                        <StyledEditItemButton to={value} title="Download">
                          <FontAwesomeIcon icon={faDownload} /> Download
                        </StyledEditItemButton>
                      </StyledActionItemButtons>
                    ),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(BuyerInvoicesList);
