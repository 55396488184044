import { useState } from "react";
import { useMutation, useQuery, useIsMutating } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faIndianRupeeSign,
  faRotateRight,
  faSearch,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import styled from "styled-components";
import JSONPretty from "react-json-pretty";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledActionItemButtons,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusPill,
  StyledModalContainer,
  StyledFormContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  MessageBox,
  Dropdown,
  TextInput,
  CustomButton,
  CustomConfirmButton,
  Form,
  Fieldset,
  Label,
  Button,
} from "../../components/FormElements";
import Modal from "../../components/Modal";
import Accordination from "../../components/Accordination/Accordination";
import { Grid, GridItem } from "../../components/Grid";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getSearchParams } from "../../utils";

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const StyledApiCallLogsContainer = styled.section`
  width: auto;
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
  padding: 20px;
  pre {
    white-space: pre-wrap;
  }
`;

const SellerTransactionsList = () => {
  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();
  const [filters, setFilters] = useState({
    fOperatorsId: "",
    fCirclesId: "",
    fCategoriesId: "",
    fRechargeStatus: "",
    fRechargeNumber: "",
    fFromDate: "",
    fToDate: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [isLogsLoading, setIsLogsLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [logs, setLogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });
  const [modalTransactionsId, setModalTransactionsId] = useState("");
  const [sellersOperatorsId, setSellersOperatorsId] = useState("");
  const [sellersApiTransactionsId, setSellersApiTransactionsId] = useState("");
  const [sellersStatus, setSellersStatus] = useState("");
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const resetResponseData = () => {
    setIsLoadingUpdate(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const updateTransaction = async (e) => {
    e.preventDefault();
    setIsLoadingUpdate(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/seller/transactions/update/${modalTransactionsId}`, {
      sellersApiTransactionsId,
      sellersOperatorsId,
      sellersStatus,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === RESPONSE_STATUSES.SUCCESS) {
          reloadData();
          setTimeout(() => {
            setShowModal(false);
            setModalTransactionsId("");
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(RESPONSE_STATUSES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoadingUpdate(false);
      });
  };

  const reloadTransaction = async (id) => {
    try {
      const response = await API.get(`/seller/transactions/reload/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/seller/transactions?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${appliedFilters.fOperatorsId}&fCirclesId=${appliedFilters.fCirclesId}&fCategoriesId=${appliedFilters.fCategoriesId}&fRechargeStatus=${appliedFilters.fRechargeStatus}&fRechargeNumber=${appliedFilters.fRechargeNumber}&fFromDate=${appliedFilters.fFromDate}&fToDate=${appliedFilters.fToDate}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const refundTransaction = async (id) => {
    try {
      const response = await API.put(
        `/seller/transactions/refund/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${appliedFilters.fOperatorsId}&fCirclesId=${appliedFilters.fCirclesId}&fCategoriesId=${appliedFilters.fCategoriesId}&fRechargeStatus=${appliedFilters.fRechargeStatus}&fRechargeNumber=${appliedFilters.fRechargeNumber}&fFromDate=${appliedFilters.fFromDate}&fToDate=${appliedFilters.fToDate}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const readApiLogs = async (id) => {
    setIsLogsLoading(true);
    try {
      const response = await API.get(`/seller/transactions/logs/${id}`);
      if (response.data.status === RESPONSE_STATUSES.SUCCESS) {
        setLogs(response?.data?.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLogsLoading(false);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["transactions", paginationData, appliedFilters],
    queryFn: readData,
  });

  const { mutateAsync: refundTransactionMutation } = useMutation({
    mutationFn: refundTransaction,
    onError: (error) => console.error(error),
    onSuccess: () => {
      reloadData();
    },
  });

  const { mutateAsync: reloadTransactionMutation } = useMutation({
    mutationFn: reloadTransaction,
    onError: (error) => console.error(error),
    onSuccess: () => {
      reloadData();
    },
  });

  const isMutation = useIsMutating();

  const reloadData = async () => await refetch();

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const filterData = async (e) => {
    e.preventDefault();
    setAppliedFilters(filters);
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFilters({
      fOperatorsId: "",
      fCirclesId: "",
      fCategoriesId: "",
      fRechargeStatus: "",
      fRechargeNumber: "",
      fFromDate: "",
      fToDate: "",
    });
    setAppliedFilters({
      fOperatorsId: "",
      fCirclesId: "",
      fCategoriesId: "",
      fRechargeStatus: "",
      fRechargeNumber: "",
      fFromDate: "",
      fToDate: "",
    });
  };

  const toggleApiLogs = async (id) => {
    setModalType("API_LOGS");
    setShowModal(true);
    await readApiLogs(id);
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Transactions"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const operatorsOptions = [
    {
      name: "OPERATOR",
      value: "",
    },
  ];
  !isEmpty(meta?.operators) &&
    meta?.operators?.forEach(({ operatorName, id }) =>
      operatorsOptions?.push({ name: operatorName, value: id })
    );

  const circlesOptions = [
    {
      name: "CIRCLE",
      value: "",
    },
  ];
  !isEmpty(meta?.circles) &&
    meta?.circles?.forEach(({ circleName, id }) =>
      circlesOptions?.push({ name: circleName, value: id })
    );

  const categoriesOptions = [
    {
      name: "CATEGORY",
      value: "",
    },
  ];
  !isEmpty(meta?.categories) &&
    meta?.categories?.forEach(({ category, id }) =>
      categoriesOptions?.push({ name: category, value: id })
    );

  const rechargeStatusOptions = [
    {
      name: "STATUS",
      value: "",
    },
    {
      name: "Pending",
      value: "Pending",
    },
    {
      name: "Success",
      value: "Success",
    },
    {
      name: "Failed",
      value: "Failed",
    },
  ];

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isMutation > 0 || isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <Dropdown
                value={filters.fOperatorsId}
                onChange={(value) => handleFilterChange("fOperatorsId", value)}
                placeholder="Operator"
                disabled={isLoading}
                options={operatorsOptions}
              />
              <Dropdown
                value={filters.fCirclesId}
                onChange={(value) => handleFilterChange("fCirclesId", value)}
                placeholder="Circle"
                disabled={isLoading}
                options={circlesOptions}
              />
              <Dropdown
                value={filters.fCategoriesId}
                onChange={(value) => handleFilterChange("fCategoriesId", value)}
                placeholder="Category"
                disabled={isLoading}
                options={categoriesOptions}
              />
              <Dropdown
                value={filters.fRechargeStatus}
                onChange={(value) =>
                  handleFilterChange("fRechargeStatus", value)
                }
                placeholder="Status"
                disabled={isLoading}
                options={rechargeStatusOptions}
              />
              <TextInput
                value={filters.fRechargeNumber}
                onChange={(value) =>
                  handleFilterChange("fRechargeNumber", value)
                }
                placeholder="Recharge Number"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={filters.fFromDate}
                onChange={(value) => handleFilterChange("fFromDate", value)}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={filters.fToDate}
                onChange={(value) => handleFilterChange("fToDate", value)}
                placeholder="To Date"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                },
                {
                  title: "Recharge Number",
                  dataSelector: "rechargeNumber",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "rechargeAmount",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Margin",
                  dataSelector: "sellersMargin",
                  dataType: "string",
                  cellrenderer: (value) => `${value}%`,
                },
                {
                  title: "Status",
                  dataSelector: "sellersStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === "Success"
                          ? "green"
                          : value === "Failed"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Operators Id",
                  dataSelector: "sellersOperatorsId",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "API Transactions Id",
                  dataSelector: "sellersApiTransactionsId",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Category",
                  dataSelector: "category",
                  dataType: "string",
                },
                {
                  title: "Transaction Type",
                  dataSelector: "transactionType",
                  dataType: "string",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "Circle",
                  dataSelector: "circleName",
                  dataType: "string",
                },
                {
                  title: "Transaction Time",
                  dataSelector: "createdOn",
                  dataType: "string",
                },
                {
                  title: "",
                  dataSelector: "id",
                  canSort: false,
                  isSticky: "Yes",
                  stickyAlign: "right",
                  isDummy: "Yes",
                  textAlign: "center",
                  width: "max-content",
                  cellrenderer: (value, item) => (
                    <StyledActionItemButtons>
                      <CustomButton
                        icon={faEye}
                        onClick={() => toggleApiLogs(value)}
                        style={{ color: "orange" }}
                      />
                      {(item.sellersStatus === "Success" ||
                        item.sellersStatus === "Pending") && (
                        <CustomConfirmButton
                          icon={faIndianRupeeSign}
                          title="Refund"
                          message="Are you sure, you want to refund this transaction ?"
                          proceedButtonText="Refund"
                          cancelButtonText="Cancel"
                          color="forestgreen"
                          hoverColor="#006200"
                          showTitle={false}
                          onClick={async () =>
                            await refundTransactionMutation(value)
                          }
                        />
                      )}
                      {(item.sellersStatus === "Success" ||
                        item.sellersStatus === "Pending") && (
                        <CustomButton
                          icon={faEdit}
                          onClick={() => {
                            setModalTransactionsId(value);
                            setSellersOperatorsId(item.sellersOperatorsId);
                            setSellersApiTransactionsId(
                              item.sellersApiTransactionsId
                            );
                            setSellersStatus(item.sellersStatus);
                            setModalType("UPDATE");
                            setShowModal(true);
                          }}
                          style={{ color: "#0095ff" }}
                        />
                      )}
                      {(item.sellersStatus === "Success" ||
                        item.sellersStatus === "Pending") && (
                        <CustomConfirmButton
                          icon={faRotateRight}
                          title="Check Status"
                          message="Are you sure, you want to check status for this transaction ?"
                          proceedButtonText="Proceed"
                          cancelButtonText="Cancel"
                          color="forestgreen"
                          hoverColor="#006200"
                          showTitle={false}
                          onClick={async () =>
                            await reloadTransactionMutation(value)
                          }
                        />
                      )}
                    </StyledActionItemButtons>
                  ),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
            <Modal
              modalStatus={showModal}
              setModalStatus={() => {
                setShowModal(false);
                setModalType("");
                setSellersApiTransactionsId("");
                setSellersOperatorsId("");
                setSellersStatus("");
                setModalTransactionsId("");
                setLogs([]);
              }}
              hideCloseButton
            >
              <StyledModalContainer
                width={modalType === "API_LOGS" ? `1200px` : `500px`}
              >
                <h3>
                  {modalType === "API_LOGS"
                    ? `API Call Logs`
                    : `Update Transaction`}
                </h3>
                {modalType === "API_LOGS" && (
                  <StyledApiCallLogsContainer>
                    {isLogsLoading && <LoadingSpinner />}
                    {!isLogsLoading && logs.length === 0 && (
                      <NoData message={`No logs found`} />
                    )}
                    {logs?.length > 0 &&
                      logs.map(
                        (
                          {
                            requestType,
                            request,
                            requestOn,
                            requestBody,
                            response,
                            responseOn,
                          },
                          index
                        ) => (
                          <Accordination
                            key={index}
                            header={`${requestType}`}
                            autoExpanded={true}
                          >
                            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                              <GridItem>
                                <StyledBodyContainer>
                                  <StyledHeading>
                                    API Request{" "}
                                    {requestOn && `( ${requestOn} )`}
                                  </StyledHeading>
                                  <StyledContent>{request}</StyledContent>
                                </StyledBodyContainer>
                                {requestBody && (
                                  <StyledBodyContainer>
                                    <StyledHeading>
                                      API Request Body
                                    </StyledHeading>
                                    <StyledContent>{requestBody}</StyledContent>
                                  </StyledBodyContainer>
                                )}
                              </GridItem>
                              <GridItem>
                                <StyledBodyContainer>
                                  <StyledHeading>
                                    API Response{" "}
                                    {responseOn && `( ${responseOn} )`}
                                  </StyledHeading>
                                  <StyledContent>
                                    <JSONPretty
                                      id="json-pretty"
                                      data={response}
                                    ></JSONPretty>
                                  </StyledContent>
                                </StyledBodyContainer>
                              </GridItem>
                            </Grid>
                          </Accordination>
                        )
                      )}
                  </StyledApiCallLogsContainer>
                )}
                {modalType === "UPDATE" && (
                  <StyledFormContainer>
                    <Form method="POST" action="#" onSubmit={updateTransaction}>
                      <Fieldset>
                        <Label required>Transaction Status</Label>
                        <Dropdown
                          value={sellersStatus}
                          onChange={setSellersStatus}
                          placeholder="Transaction Status"
                          options={[
                            {
                              name: "-- SELECT STATUS --",
                              value: "",
                            },
                            {
                              name: "Pending",
                              value: "Pending",
                            },
                            {
                              name: "Success",
                              value: "Success",
                            },
                            {
                              name: "Failed",
                              value: "Failed",
                            },
                          ]}
                          disabled={isLoadingUpdate}
                        />
                      </Fieldset>
                      <Fieldset>
                        <Label required>API Transactions ID</Label>
                        <TextInput
                          value={sellersApiTransactionsId}
                          onChange={setSellersApiTransactionsId}
                          placeholder="Please enter api transactions id"
                          disabled={isLoadingUpdate}
                        />
                      </Fieldset>
                      <Fieldset>
                        <Label required={sellersStatus === "Success"}>
                          API Operators ID
                        </Label>
                        <TextInput
                          value={sellersOperatorsId}
                          onChange={setSellersOperatorsId}
                          placeholder="Please enter api operators id"
                          disabled={isLoadingUpdate}
                        />
                      </Fieldset>
                      {showModal && responseStatus !== "" && (
                        <Fieldset>
                          <MessageBox
                            status={responseStatus}
                            message={responseMessage}
                          />
                        </Fieldset>
                      )}
                      <Fieldset>
                        <Button
                          disabled={isLoadingUpdate}
                          type="submit"
                          style={{ width: "auto" }}
                        >
                          Update
                        </Button>
                      </Fieldset>
                      <Loader isLoading={isLoadingUpdate} />
                    </Form>
                  </StyledFormContainer>
                )}
              </StyledModalContainer>
            </Modal>
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(SellerTransactionsList);
