import { useState } from "react";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  MessageBox,
  Button,
  TextInput,
} from "../../components/FormElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { getAuthData, getBase64 } from "../../utils";
import { Heading1 } from "../../components/Headings";

const StyledKYCPendingMessageContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 50px 0px;
  svg {
    font-size: 60px;
    color: #0095ff;
  }
  h1 {
    margin: 0px;
    padding: 0px;
  }
  p {
    font-size: 14px;
    color: #222222;
    margin: 0px;
    padding: 0px;
  }
`;

const StyledInput = styled.input`
  background-color: #f9fafb;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  padding: 15px;
  margin: 0px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: calc(100% - 32px);
  background-color: #f9fafb;
  outline: 0px;
  flex: 2;
  &:focus {
    outline: 1px solid #dbdbdb;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #e1e1e1;
    border: 1px solid #d3d3d3;
  }
`;

const KYCPending = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    panName: "",
    panNumber: "",
    panFile: "",
    aadhaarName: "",
    aadhaarNumber: "",
    aadhaarFile: "",
    gstName: "",
    gstNumber: "",
    gstFile: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/authentication/kyc", {
        panName: state.panName,
        panNumber: state.panNumber,
        panFile: state.panFile,
        aadhaarName: state.aadhaarName,
        aadhaarNumber: state.aadhaarNumber,
        aadhaarFile: state.aadhaarFile,
        gstName: state.gstName,
        gstNumber: state.gstNumber,
        gstFile: state.gstFile,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
          }));
          window.location.reload();
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const {
    userProfile: { kycStatus, kycRemark },
  } = getAuthData();

  return kycStatus === "Submitted" ? (
    <>
      <StyledKYCPendingMessageContainer>
        <FontAwesomeIcon icon={faExclamationCircle} />
        <Heading1>Pending KYC Verification</Heading1>
        <p>Your KYC information is submitted for verification.</p>
      </StyledKYCPendingMessageContainer>
    </>
  ) : (
    <>
      <PageHeader title="KYC Pending" />
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action="#"
            method="POST"
            onSubmit={onSubmit}
            style={{ maxWidth: "500px" }}
          >
            {!isEmpty(kycRemark) && kycStatus === "Rejected" && (
              <Fieldset>
                <MessageBox
                  status="Rejected"
                  message={`Rejected Reason : ${kycRemark}`}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label required>Name on PAN</Label>
              <TextInput
                value={state.panName}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    panName: value,
                  }))
                }
                placeholder="Name on PAN"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>PAN Number</Label>
              <TextInput
                value={state.panNumber}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    panNumber: value,
                  }))
                }
                placeholder="PAN Number"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>PAN File</Label>
              <StyledInput
                type="file"
                onChange={(e) => {
                  getBase64(e.target.files[0], (panFileBase64) => {
                    setState((prevState) => ({
                      ...prevState,
                      panFile: panFileBase64,
                    }));
                  });
                }}
                placeholder="PAN File"
                disabled={state.isLoading}
                accept="image/png"
              />
            </Fieldset>
            <Fieldset>
              <Label required>Name on Aadhaar</Label>
              <TextInput
                value={state.aadhaarName}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    aadhaarName: value,
                  }))
                }
                placeholder="Name on Aadhaar"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Aadhaar Number</Label>
              <TextInput
                value={state.aadhaarNumber}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    aadhaarNumber: value,
                  }))
                }
                placeholder="Aadhaar Number"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Aadhaar File</Label>
              <StyledInput
                type="file"
                onChange={(e) => {
                  getBase64(e.target.files[0], (aadhaarFileBase64) => {
                    setState((prevState) => ({
                      ...prevState,
                      aadhaarFile: aadhaarFileBase64,
                    }));
                  });
                }}
                placeholder="Aadhaar File"
                disabled={state.isLoading}
                accept="image/png"
              />
            </Fieldset>
            <Fieldset>
              <Label required>Name on GST</Label>
              <TextInput
                value={state.gstName}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    gstName: value,
                  }))
                }
                placeholder="Name on GST"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>GST Number</Label>
              <TextInput
                value={state.gstNumber}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    gstNumber: value,
                  }))
                }
                placeholder="GST Number"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>GST File</Label>
              <StyledInput
                type="file"
                onChange={(e) => {
                  getBase64(e.target.files[0], (gstFileBase64) => {
                    setState((prevState) => ({
                      ...prevState,
                      gstFile: gstFileBase64,
                    }));
                  });
                }}
                placeholder="GST File"
                disabled={state.isLoading}
                accept="image/png"
              />
            </Fieldset>
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading}>Submit</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(KYCPending);
