import { useState } from "react";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
  StyledMicroButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  Legend,
  TextInput,
  Dropdown,
  MessageBox,
  Button,
} from "../../components/FormElements";
import { Grid, GridItem } from "../../components/Grid";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getReturnUrl } from "../../utils";

const SellerAPISettingsCreate = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    apiName: "",
    ipAddress: "",
    apiEndpoint: "",
    apiRequestMethod: "",
    apiRequestBody: "",
    apiResponseType: "",
    statusKey: "",
    successValue: "",
    failureValue: "",
    transactionIdKey: "",
    operatorsIdKey: "",
    messageKey: "",
    canCheckStatus: "No",
    checkStatusApiEndpoint: "",
    checkStatusApiRequestMethod: "",
    checkStatusApiRequestBody: "",
    checkStatusApiResponseType: "",
    checkStatusStatusKey: "",
    checkStatusSuccessValue: "",
    checkStatusFailureValue: "",
    checkStatusOperatorsIdKey: "",
    canBalanceCheck: "No",
    balanceCheckApiEndpoint: "",
    balanceCheckApiRequestMethod: "",
    balanceCheckApiRequestBody: "",
    balanceCheckApiResponseType: "",
    balanceCheckBalanceKey: "",
    canDisputeCheck: "No",
    disputeApiEndpoint: "",
    disputeApiRequestMethod: "",
    disputeApiRequestBody: "",
    disputeApiResponseType: "",
    callbackOurTransactionId: "",
    callbackApiTransactionId: "",
    callbackStatusKey: "",
    callbackSuccessValue: "",
    callbackFailureValue: "",
    callbackOperatorsId: "",
    apiStatus: "Active",
  });

  const requestOptions = [
    {
      name: "-- SELECT REQUEST METHOD --",
      value: "",
    },
    {
      name: "GET",
      value: "GET",
    },
    {
      name: "POST",
      value: "POST",
    },
  ];

  const responseOptions = [
    {
      name: "-- SELECT RESPONSE TYPE --",
      value: "",
    },
    {
      name: "JSON",
      value: "JSON",
    },
    {
      name: "XML",
      value: "XML",
    },
  ];

  const yesNoOptions = [
    {
      name: "-- SELECT STATUS --",
      value: "",
    },
    {
      name: "Yes",
      value: "Yes",
    },
    {
      name: "No",
      value: "No",
    },
  ];

  const apiStatusOptions = [
    {
      name: "-- SELECT API STATUS --",
      value: "",
    },
    {
      name: "Active",
      value: "Active",
    },
    {
      name: "Inactive",
      value: "Inactive",
    },
  ];

  const addText = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: prevState[key] + value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/seller/api-settings", {
        apiName: state.apiName,
        ipAddress: state.ipAddress,
        apiEndpoint: state.apiEndpoint,
        apiRequestMethod: state.apiRequestMethod,
        apiRequestBody: state.apiRequestBody,
        apiResponseType: state.apiResponseType,
        statusKey: state.statusKey,
        successValue: state.successValue,
        failureValue: state.failureValue,
        transactionIdKey: state.transactionIdKey,
        operatorsIdKey: state.operatorsIdKey,
        messageKey: state.messageKey,
        canCheckStatus: state.canCheckStatus,
        checkStatusApiEndpoint: state.checkStatusApiEndpoint,
        checkStatusApiRequestMethod: state.checkStatusApiRequestMethod,
        checkStatusApiRequestBody: state.checkStatusApiRequestBody,
        checkStatusApiResponseType: state.checkStatusApiResponseType,
        checkStatusStatusKey: state.checkStatusStatusKey,
        checkStatusSuccessValue: state.checkStatusSuccessValue,
        checkStatusFailureValue: state.checkStatusFailureValue,
        checkStatusOperatorsIdKey: state.checkStatusOperatorsIdKey,
        canBalanceCheck: state.canBalanceCheck,
        balanceCheckApiEndpoint: state.balanceCheckApiEndpoint,
        balanceCheckApiRequestMethod: state.balanceCheckApiRequestMethod,
        balanceCheckApiRequestBody: state.balanceCheckApiRequestBody,
        balanceCheckApiResponseType: state.balanceCheckApiResponseType,
        balanceCheckBalanceKey: state.balanceCheckBalanceKey,
        canDisputeCheck: state.canDisputeCheck,
        disputeApiEndpoint: state.disputeApiEndpoint,
        disputeApiRequestMethod: state.disputeApiRequestMethod,
        disputeApiRequestBody: state.disputeApiRequestBody,
        disputeApiResponseType: state.disputeApiResponseType,
        callbackOurTransactionId: state.callbackOurTransactionId,
        callbackApiTransactionId: state.callbackApiTransactionId,
        callbackStatusKey: state.callbackStatusKey,
        callbackSuccessValue: state.callbackSuccessValue,
        callbackFailureValue: state.callbackFailureValue,
        callbackOperatorsId: state.callbackOperatorsId,
        apiStatus: state.apiStatus,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
          apiName: "",
          ipAddress: "",
          apiEndpoint: "",
          apiRequestMethod: "",
          apiRequestBody: "",
          apiResponseType: "",
          statusKey: "",
          successValue: "",
          failureValue: "",
          transactionIdKey: "",
          operatorsIdKey: "",
          messageKey: "",
          canCheckStatus: "No",
          checkStatusApiEndpoint: "",
          checkStatusApiRequestMethod: "",
          checkStatusApiRequestBody: "",
          checkStatusApiResponseType: "",
          checkStatusStatusKey: "",
          checkStatusSuccessValue: "",
          checkStatusFailureValue: "",
          checkStatusOperatorsIdKey: "",
          canBalanceCheck: "No",
          balanceCheckApiEndpoint: "",
          balanceCheckApiRequestMethod: "",
          balanceCheckApiRequestBody: "",
          balanceCheckApiResponseType: "",
          balanceCheckBalanceKey: "",
          canDisputeCheck: "No",
          disputeApiEndpoint: "",
          disputeApiRequestMethod: "",
          disputeApiRequestBody: "",
          disputeApiResponseType: "",
          callbackOurTransactionId: "",
          callbackApiTransactionId: "",
          callbackStatusKey: "",
          callbackSuccessValue: "",
          callbackFailureValue: "",
          callbackOperatorsId: "",
          apiStatus: "Active",
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
          }));
          window.location.href = getReturnUrl("create");
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const renderPageHeading = () => (
    <PageHeader title="Add API" showBackButton={true} />
  );

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form action="#" method="POST" onSubmit={onSubmit}>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset display="block">
                  <Label required>API Name</Label>
                  <TextInput
                    value={state.apiName}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        apiName: value,
                      }))
                    }
                    placeholder="Please enter api name"
                    maxLength={100}
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset display="block">
                  <Label required>IP Address</Label>
                  <TextInput
                    value={state.ipAddress}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        ipAddress: value,
                      }))
                    }
                    placeholder="Please enter ip address"
                    maxLength={15}
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset display="block">
              <Label required>API Endpoint</Label>
              <TextInput
                value={state.apiEndpoint}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    apiEndpoint: value,
                  }))
                }
                placeholder="Please enter api endpoint"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset display="block">
              <Label required>API Request Body</Label>
              <TextInput
                value={state.apiRequestBody}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    apiRequestBody: value,
                  }))
                }
                placeholder="Please enter api request body"
                disabled={state.isLoading}
              />
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() => addText("apiRequestBody", "{{{NUMBER}}}")}
              >
                Mobile/Account Number
              </StyledMicroButton>
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() => addText("apiRequestBody", "{{{OPERATOR}}}")}
              >
                Operator
              </StyledMicroButton>
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() => addText("apiRequestBody", "{{{AMOUNT}}}")}
              >
                Amount
              </StyledMicroButton>
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() =>
                  addText("apiRequestBody", "{{{TRANSACTION_ID}}}")
                }
              >
                Transaction Id
              </StyledMicroButton>
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() => addText("apiRequestBody", "{{{FIELD1}}}")}
              >
                Field 1
              </StyledMicroButton>
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() => addText("apiRequestBody", "{{{FIELD2}}}")}
              >
                Field 2
              </StyledMicroButton>
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() => addText("apiRequestBody", "{{{FIELD3}}}")}
              >
                Field 3
              </StyledMicroButton>
              <StyledMicroButton
                disabled={state.isLoading}
                type="button"
                onClick={() => addText("apiRequestBody", "{{{FIELD4}}}")}
              >
                Field 4
              </StyledMicroButton>
            </Fieldset>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset display="block">
                  <Label required>API Request Method</Label>
                  <Dropdown
                    value={state.apiRequestMethod}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        apiRequestMethod: value,
                      }))
                    }
                    placeholder="Please select api request method"
                    disabled={state.isLoading}
                    options={requestOptions}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset display="block">
                  <Label required>API Response Type</Label>
                  <Dropdown
                    value={state.apiResponseType}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        apiResponseType: value,
                      }))
                    }
                    placeholder="Please select api response type"
                    disabled={state.isLoading}
                    options={responseOptions}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <GridItem>
                <Fieldset display="block">
                  <Label required>Status Key</Label>
                  <TextInput
                    value={state.statusKey}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        statusKey: value,
                      }))
                    }
                    placeholder="Please enter status key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset display="block">
                  <Label required>Success Value</Label>
                  <TextInput
                    value={state.successValue}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        successValue: value,
                      }))
                    }
                    placeholder="Please enter success value"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset display="block">
                  <Label required>Failure Value</Label>
                  <TextInput
                    value={state.failureValue}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        failureValue: value,
                      }))
                    }
                    placeholder="Please enter failure value"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <GridItem>
                <Fieldset display="block">
                  <Label required>Transaction ID Key</Label>
                  <TextInput
                    value={state.transactionIdKey}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        transactionIdKey: value,
                      }))
                    }
                    placeholder="Please enter transaction id key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset display="block">
                  <Label required>Operators ID Key</Label>
                  <TextInput
                    value={state.operatorsIdKey}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        operatorsIdKey: value,
                      }))
                    }
                    placeholder="Please enter operators id key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset display="block">
                  <Label>Message Key</Label>
                  <TextInput
                    value={state.messageKey}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        messageKey: value,
                      }))
                    }
                    placeholder="Please enter message key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Fieldset display="block">
              <Label required>Can Check Status?</Label>
              <Dropdown
                value={state.canCheckStatus}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    canCheckStatus: value,
                  }))
                }
                placeholder="Please select if status can be checked via api"
                disabled={state.isLoading}
                options={yesNoOptions}
              />
            </Fieldset>
            {state.canCheckStatus === "Yes" && (
              <Fieldset forLegend display="block">
                <Legend>Check Status API Configuration</Legend>
                <Fieldset display="block">
                  <Label required>Check Status API Endpoint</Label>
                  <TextInput
                    value={state.checkStatusApiEndpoint}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        checkStatusApiEndpoint: value,
                      }))
                    }
                    placeholder="Please enter check status api endpoint"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Fieldset display="block">
                  <Label required>Check Status API Request Body</Label>
                  <TextInput
                    value={state.checkStatusApiRequestBody}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        checkStatusApiRequestBody: value,
                      }))
                    }
                    placeholder="Please enter check status api request body"
                    disabled={state.isLoading}
                  />
                  <StyledMicroButton
                    disabled={state.isLoading}
                    type="button"
                    onClick={() =>
                      addText(
                        "checkStatusApiRequestBody",
                        "{{{TRANSACTION_ID}}}"
                      )
                    }
                  >
                    Transaction Id
                  </StyledMicroButton>
                  <StyledMicroButton
                    disabled={state.isLoading}
                    type="button"
                    onClick={() =>
                      addText(
                        "checkStatusApiRequestBody",
                        "{{{API_TRANSACTION_ID}}}"
                      )
                    }
                  >
                    API Transaction Id
                  </StyledMicroButton>
                </Fieldset>
                <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Check Status API Request Method</Label>
                      <Dropdown
                        value={state.checkStatusApiRequestMethod}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            checkStatusApiRequestMethod: value,
                          }))
                        }
                        placeholder="Please select check status api request method"
                        disabled={state.isLoading}
                        options={requestOptions}
                      />
                    </Fieldset>
                  </GridItem>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Check Status API Response Type</Label>
                      <Dropdown
                        value={state.checkStatusApiResponseType}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            checkStatusApiResponseType: value,
                          }))
                        }
                        placeholder="Please select check status api response type"
                        disabled={state.isLoading}
                        options={responseOptions}
                      />
                    </Fieldset>
                  </GridItem>
                </Grid>
                <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Check Status Status Key</Label>
                      <TextInput
                        value={state.checkStatusStatusKey}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            checkStatusStatusKey: value,
                          }))
                        }
                        placeholder="Please enter check status status key"
                        disabled={state.isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Check Status Operators ID Key</Label>
                      <TextInput
                        value={state.checkStatusOperatorsIdKey}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            checkStatusOperatorsIdKey: value,
                          }))
                        }
                        placeholder="Please enter check status operators id key"
                        disabled={state.isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                </Grid>
                <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Check Status success value</Label>
                      <TextInput
                        value={state.checkStatusSuccessValue}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            checkStatusSuccessValue: value,
                          }))
                        }
                        placeholder="Please enter check status success value"
                        disabled={state.isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Check Status failure value</Label>
                      <TextInput
                        value={state.checkStatusFailureValue}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            checkStatusFailureValue: value,
                          }))
                        }
                        placeholder="Please enter check status failure value"
                        disabled={state.isLoading}
                      />
                    </Fieldset>
                  </GridItem>
                </Grid>
              </Fieldset>
            )}
            <Fieldset display="block">
              <Label required>Can Check Balance?</Label>
              <Dropdown
                value={state.canBalanceCheck}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    canBalanceCheck: value,
                  }))
                }
                placeholder="Please select if balance can be checked via api"
                disabled={state.isLoading}
                options={yesNoOptions}
              />
            </Fieldset>
            {state.canBalanceCheck === "Yes" && (
              <Fieldset forLegend display="block">
                <Legend>Balance Check API Configuration</Legend>
                <Fieldset display="block">
                  <Label required>Balance Check API Endpoint</Label>
                  <TextInput
                    value={state.balanceCheckApiEndpoint}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        balanceCheckApiEndpoint: value,
                      }))
                    }
                    placeholder="Please enter balance check api endpoint"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Fieldset display="block">
                  <Label required>Balance Check API Request Body</Label>
                  <TextInput
                    value={state.balanceCheckApiRequestBody}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        balanceCheckApiRequestBody: value,
                      }))
                    }
                    placeholder="Please enter balance check api request body"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Balance Check API Request Method</Label>
                      <Dropdown
                        value={state.balanceCheckApiRequestMethod}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            balanceCheckApiRequestMethod: value,
                          }))
                        }
                        placeholder="Please select balance check api request method"
                        disabled={state.isLoading}
                        options={requestOptions}
                      />
                    </Fieldset>
                  </GridItem>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Balance Check API Response Type</Label>
                      <Dropdown
                        value={state.balanceCheckApiResponseType}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            balanceCheckApiResponseType: value,
                          }))
                        }
                        placeholder="Please select balance check api response type"
                        disabled={state.isLoading}
                        options={responseOptions}
                      />
                    </Fieldset>
                  </GridItem>
                </Grid>
                <Fieldset display="block">
                  <Label required>Balance Check Balance Key</Label>
                  <TextInput
                    value={state.balanceCheckBalanceKey}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        balanceCheckBalanceKey: value,
                      }))
                    }
                    placeholder="Please enter balance check balance key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </Fieldset>
            )}
            <Fieldset display="block">
              <Label required>Can Dispute Check?</Label>
              <Dropdown
                value={state.canDisputeCheck}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    canDisputeCheck: value,
                  }))
                }
                placeholder="Please select if dispute can be checked via api"
                disabled={state.isLoading}
                options={yesNoOptions}
              />
            </Fieldset>
            {state.canDisputeCheck === "Yes" && (
              <Fieldset forLegend display="block">
                <Legend>Dispute Check API Configuration</Legend>
                <Fieldset display="block">
                  <Label required>Dispute Check API Endpoint</Label>
                  <TextInput
                    value={state.disputeApiEndpoint}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        disputeApiEndpoint: value,
                      }))
                    }
                    placeholder="Please enter dispute check api endpoint"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Fieldset display="block">
                  <Label required>Dispute Check API Request Body</Label>
                  <TextInput
                    value={state.disputeApiRequestBody}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        disputeApiRequestBody: value,
                      }))
                    }
                    placeholder="Please enter dispute check api request body"
                    disabled={state.isLoading}
                  />
                  <StyledMicroButton
                    disabled={state.isLoading}
                    type="button"
                    onClick={() =>
                      addText("disputeApiRequestBody", "{{{TRANSACTION_ID}}}")
                    }
                  >
                    Transaction Id
                  </StyledMicroButton>
                  <StyledMicroButton
                    disabled={state.isLoading}
                    type="button"
                    onClick={() =>
                      addText(
                        "disputeApiRequestBody",
                        "{{{API_TRANSACTION_ID}}}"
                      )
                    }
                  >
                    API Transaction Id
                  </StyledMicroButton>
                </Fieldset>
                <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Dispute Check API Request Method</Label>
                      <Dropdown
                        value={state.disputeApiRequestMethod}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            disputeApiRequestMethod: value,
                          }))
                        }
                        placeholder="Please select dispute check api request method"
                        disabled={state.isLoading}
                        options={requestOptions}
                      />
                    </Fieldset>
                  </GridItem>
                  <GridItem>
                    <Fieldset display="block">
                      <Label required>Dispute Check API Response Type</Label>
                      <Dropdown
                        value={state.disputeApiResponseType}
                        onChange={(value) =>
                          setState((prevState) => ({
                            ...prevState,
                            disputeApiResponseType: value,
                          }))
                        }
                        placeholder="Please select dispute check api response type"
                        disabled={state.isLoading}
                        options={responseOptions}
                      />
                    </Fieldset>
                  </GridItem>
                </Grid>
              </Fieldset>
            )}
            <Fieldset forLegend display="block">
              <Legend>Callback Configuration</Legend>
              <Grid
                columns={
                  "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
                }
              >
                <Fieldset display="block">
                  <Label required>Status Key</Label>
                  <TextInput
                    value={state.callbackStatusKey}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        callbackStatusKey: value,
                      }))
                    }
                    placeholder="Please enter status key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Fieldset display="block">
                  <Label required>Success Value</Label>
                  <TextInput
                    value={state.callbackSuccessValue}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        callbackSuccessValue: value,
                      }))
                    }
                    placeholder="Please enter success value"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Fieldset display="block">
                  <Label required>Failure Value</Label>
                  <TextInput
                    value={state.callbackFailureValue}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        callbackFailureValue: value,
                      }))
                    }
                    placeholder="Please enter failure value"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </Grid>
              <Grid
                columns={
                  "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
                }
              >
                <Fieldset display="block">
                  <Label required>Our Transaction Id Key</Label>
                  <TextInput
                    value={state.callbackOurTransactionId}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        callbackOurTransactionId: value,
                      }))
                    }
                    placeholder="Please enter our transaction id key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Fieldset display="block">
                  <Label required>API Transaction Id Key</Label>
                  <TextInput
                    value={state.callbackApiTransactionId}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        callbackApiTransactionId: value,
                      }))
                    }
                    placeholder="Please enter api transaction id key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
                <Fieldset display="block">
                  <Label required>Operators ID Key</Label>
                  <TextInput
                    value={state.callbackOperatorsId}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        callbackOperatorsId: value,
                      }))
                    }
                    placeholder="Please enter operators id key"
                    disabled={state.isLoading}
                  />
                </Fieldset>
              </Grid>
            </Fieldset>
            <Fieldset display="block">
              <Label required>API Status</Label>
              <Dropdown
                value={state.apiStatus}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    apiStatus: value,
                  }))
                }
                placeholder="Please select api status"
                disabled={state.isLoading}
                options={apiStatusOptions}
              />
            </Fieldset>
            {state.responseStatus && state.responseMessage && (
              <Fieldset display="block">
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset display="block">
              <Button disabled={state.isLoading}>Create</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(SellerAPISettingsCreate);
