import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  Button,
} from "../../components/FormElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getReturnUrl } from "../../utils";
import { Image } from "../../components/Image";

const StyledBankAccounts = styled.section`
  display: flex;
  flex-direction: row;
  gap: 0px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const StyledBankAccountsOuterCard = styled.section`
  border: 5px solid #ffffff;
  padding: 0px;
  border-radius: 18px;
  cursor: pointer;
  &.active {
    border-color: #0095ff;
  }
`;

const StyledBankAccountsCard = styled.section`
  width: 300px;
  height: auto;
  border: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  column-gap: 20px;
  h1 {
    font-size: 20px;
    font-weight: normal;
    color: #000000;
    margin: 0px;
    padding: 0px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
  }
`;

const StyledBankLogoContainer = styled.section`
  width: 50px;
  height: 50px;
`;

const StyledBankDetailsContainer = styled.section`
  flex: 1;
`;

const WalletTopupsCreate = () => {
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    banksId: "",
    paymentType: "",
    walletType: "GST",
    amount: "",
    utrNumber: "",
  });

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const paymentTypeOptions = [
    {
      name: "-- SELECT PAYMENT TYPE --",
      value: "",
    },
    {
      name: "IMPS",
      value: "IMPS",
    },
    {
      name: "NEFT",
      value: "NEFT",
    },
    {
      name: "RTGS",
      value: "RTGS",
    },
  ];

  const walletTypeOptions = [
    {
      name: "-- SELECT WALLET TYPE --",
      value: "",
    },
    {
      name: "GST",
      value: "GST",
    },
    {
      name: "Non-GST",
      value: "Non-GST",
    },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/payments/wallet-topups", {
        banksId: state.banksId,
        paymentType: state.paymentType,
        walletType: state.walletType,
        amount: state.amount,
        utrNumber: state.utrNumber,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
          banksId: "",
          paymentType: "",
          walletType: "GST",
          amount: "",
          utrNumber: "",
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
          }));
          window.location.href = getReturnUrl("create");
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const renderPageHeading = () => (
    <PageHeader title="Topup Wallet" showBackButton={true} />
  );

  const adminBankAccounts = meta?.adminBankAccounts || [];

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action="#"
            method="POST"
            onSubmit={onSubmit}
            style={{ maxWidth: "500px" }}
          >
            <Fieldset>
              <Label required>Select Bank Account</Label>
            </Fieldset>
            <StyledBankAccounts>
              {adminBankAccounts.map(
                ({
                  id,
                  bankName,
                  bankLogo,
                  payeeName,
                  accountNumber,
                  ifscCode,
                }) => {
                  return (
                    <StyledBankAccountsOuterCard
                      className={id === state.banksId ? "active" : ""}
                    >
                      <StyledBankAccountsCard
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            banksId: id,
                          }));
                        }}
                      >
                        <StyledBankLogoContainer>
                          <Image
                            source={bankLogo || "no-image.png"}
                            alt={bankName}
                          />
                        </StyledBankLogoContainer>
                        <StyledBankDetailsContainer>
                          <h1>{bankName}</h1>
                          <p>{payeeName}</p>
                          <p>{accountNumber}</p>
                          <p>{ifscCode}</p>
                        </StyledBankDetailsContainer>
                      </StyledBankAccountsCard>
                    </StyledBankAccountsOuterCard>
                  );
                }
              )}
            </StyledBankAccounts>
            <Fieldset>
              <Label required>Payment Type</Label>
              <Dropdown
                value={state.paymentType}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    paymentType: value,
                  }))
                }
                placeholder="Payment Type"
                disabled={state.isLoading}
                options={paymentTypeOptions}
              />
            </Fieldset>
            {meta?.nonGstWalletStatus === "Active" && (
              <Fieldset>
                <Label required>Wallet Type</Label>
                <Dropdown
                  value={state.walletType}
                  onChange={(value) =>
                    setState((prevState) => ({
                      ...prevState,
                      walletType: value,
                    }))
                  }
                  placeholder="Wallet Type"
                  disabled={state.isLoading}
                  options={walletTypeOptions}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label required>Amount</Label>
              <TextInput
                value={state.amount}
                isNumeric={true}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    amount: value,
                  }))
                }
                placeholder="Amount"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>UTR Number</Label>
              <TextInput
                value={state.utrNumber}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    utrNumber: value,
                  }))
                }
                placeholder="UTR Number"
                disabled={state.isLoading}
              />
            </Fieldset>
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading}>Create</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(WalletTopupsCreate);
