import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { withAppHOC } from "../../hoc";
import { StyledContentContainer } from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox } from "../../components/FormElements";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getSearchParams } from "../../utils";
import Loader from "../../components/Loader/Loader";

const Logs = () => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const fetchData = async () => {
    const response = await API.get(
      `/authentication/logs/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    );
    return response.data;
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["logs", paginationData],
    queryFn: fetchData,
  });

  const reloadData = async () => await refetch();

  const renderPageHeading = () => (
    <PageHeader
      title="Your Account Logs"
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        {data?.data && data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "IP Address",
                  dataSelector: "ipAddress",
                  dataType: "string",
                },
                {
                  title: "Date & Time",
                  dataSelector: "createdOn",
                  dataType: "string",
                },
                {
                  title: "Platform",
                  dataSelector: "platform",
                  dataType: "string",
                },
                {
                  title: "Device",
                  dataSelector: "deviceType",
                  dataType: "string",
                },
                {
                  title: "Operating System",
                  dataSelector: "operatingSystem",
                  dataType: "string",
                },
                {
                  title: "Location",
                  dataSelector: "location",
                  dataType: "string",
                },
                { title: "Action", dataSelector: "action", dataType: "string" },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Logs);
