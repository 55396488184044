import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import GlobalStyles from "./styles";
import { getAuthData } from "./utils";
import { KYC_STATUS } from "./constants";
import Register from "./screens/Authentication/Register";
import Login from "./screens/Authentication/Login";
import ForgotPassword from "./screens/Authentication/ForgotPassword";
import KYCPending from "./screens/Authentication/KYCPending";
import PrivacyPolicy from "./screens/PublicPages/PrivacyPolicy";
import TermsAndConditions from "./screens/PublicPages/TermsAndConditions";
import RefundPolicy from "./screens/PublicPages/RefundPolicy";
import Contact from "./screens/PublicPages/Contact";
import Profile from "./screens/Account/Profile";
import ChangePassword from "./screens/Account/ChangePassword";
import Logs from "./screens/Account/Logs";
import Sessions from "./screens/Account/Sessions";
import Dashboard from "./screens/Dashboard/Dashboard";
import WalletTopupsList from "./screens/Payments/WalletTopupsList";
import WalletTopupsCreate from "./screens/Payments/WalletTopupsCreate";
import BankAccountsList from "./screens/Payments/BankAccountsList";
import BankAccountsCreate from "./screens/Payments/BankAccountsCreate";
import WithdrawFundsList from "./screens/Payments/WithdrawFundsList";
import WithdrawFundsCreate from "./screens/Payments/WithdrawFundsCreate";
import SwitchFundsList from "./screens/Payments/SwitchFundsList";
import SwitchFundsCreate from "./screens/Payments/SwitchFundsCreate";
import AvailableMarginsList from "./screens/Buyer/AvailableMarginsList";
import BuyerMarginsList from "./screens/Buyer/BuyerMarginsList";
import BuyerMarginsCreate from "./screens/Buyer/BuyerMarginsCreate";
import BuyerMarginsUpdate from "./screens/Buyer/BuyerMarginsUpdate";
import BuyerTransactionsList from "./screens/Buyer/BuyerTransactionsList";
import BuyerTransactionsPendingList from "./screens/Buyer/BuyerTransactionsPendingList";
import BuyerTransactionsComplaintsList from "./screens/Buyer/BuyerTransactionsComplaintsList";
import BuyerAPICredentials from "./screens/Buyer/BuyerAPICredentials";
import BuyerAPISettings from "./screens/Buyer/BuyerAPISettings";
import BuyerAPIDocumentation from "./screens/Buyer/BuyerAPIDocumentation";
import SellerAPISettingsList from "./screens/Seller/SellerAPISettingsList";
import SellerAPISettingsCreate from "./screens/Seller/SellerAPISettingsCreate";
import SellerAPISettingsUpdate from "./screens/Seller/SellerAPISettingsUpdate";
import SellerMarginsList from "./screens/Seller/SellerMarginsList";
import SellerMarginsCreate from "./screens/Seller/SellerMarginsCreate";
import SellerMarginsUpdate from "./screens/Seller/SellerMarginsUpdate";
import SellerTransactionsList from "./screens/Seller/SellerTransactionsList";
import SellerTransactionsPendingList from "./screens/Seller/SellerTransactionsPendingList";
import SellerTransactionsComplaintsList from "./screens/Seller/SellerTransactionsComplaintsList";
import OperatorDaybook from "./screens/Reports/OperatorDaybook";
import Daybook from "./screens/Reports/Daybook";
import BuyerWalletTransactions from "./screens/Reports/BuyerWalletTransactions";
import SellerWalletTransactions from "./screens/Reports/SellerWalletTransactions";
import BuyerInvoicesList from "./screens/Invoicing/BuyerInvoicesList";
import SellerInvoicesList from "./screens/Invoicing/SellerInvoicesList";
import FirmDetails from "./screens/FirmDetails/FirmDetails";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Prevent data from being cached
      cacheTime: 0,
      // Prevent queries from reusing stale data
      staleTime: 0,
      // Ensure data is always refetched
      refetchOnWindowFocus: false, // Default behavior; refetch on focus
      refetchOnReconnect: true, // Refetch on network reconnect
      refetchInterval: false, // Disable background refetching
    },
  },
});

const ProtectedRoutes = () => {
  const { isLoggedIn, userProfile } = getAuthData();
  if (isLoggedIn && userProfile?.kycStatus !== KYC_STATUS.APPROVED) {
    return <Navigate to="/kyc" />;
  }
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirectUrl=${window.location.href}`} />
  );
};

const AuthRoutes = () => {
  const { isLoggedIn } = getAuthData();
  return isLoggedIn ? <Navigate to="/" /> : <Outlet />;
};

const KYCRoutes = () => {
  const { isLoggedIn, userProfile } = getAuthData();
  return isLoggedIn && userProfile?.kycStatus === KYC_STATUS.APPROVED ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

const App = () => {
  const theme = {
    colors: {
      authBackgroundColor: "#ffffff",
      primaryColor: "#0095ff",
      secondaryColor: "#007fd9",
      tertiaryColor: "#0067b0",
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route exact path="/" element={<Dashboard />} />
              <Route path={"/profile"} element={<Profile />} />
              <Route path={"/change-password"} element={<ChangePassword />} />
              <Route path={"/logs"} element={<Logs />} />
              <Route path={"/sessions"} element={<Sessions />} />
              <Route
                path={"/payments/wallet-topups"}
                element={<WalletTopupsList />}
              />
              <Route
                path={"/payments/wallet-topups/create"}
                element={<WalletTopupsCreate />}
              />
              <Route
                path={"/payments/bank-accounts"}
                element={<BankAccountsList />}
              />
              <Route
                path={"/payments/bank-accounts/create"}
                element={<BankAccountsCreate />}
              />
              <Route
                path={"/payments/withdraw-funds"}
                element={<WithdrawFundsList />}
              />
              <Route
                path={"/payments/withdraw-funds/create"}
                element={<WithdrawFundsCreate />}
              />
              <Route
                path={"/payments/switch-funds"}
                element={<SwitchFundsList />}
              />
              <Route
                path={"/payments/switch-funds/create"}
                element={<SwitchFundsCreate />}
              />
              <Route
                path={"/buyer/available-margins"}
                element={<AvailableMarginsList />}
              />
              <Route path={"/buyer/margins"} element={<BuyerMarginsList />} />
              <Route
                path={"/buyer/margins/create"}
                element={<BuyerMarginsCreate />}
              />
              <Route
                path={"/buyer/margins/update/:id"}
                element={<BuyerMarginsUpdate />}
              />
              <Route
                path={"/buyer/transactions"}
                element={<BuyerTransactionsList />}
              />
              <Route
                path={"/buyer/transactions-pending"}
                element={<BuyerTransactionsPendingList />}
              />
              <Route
                path={"/buyer/transactions-complaints"}
                element={<BuyerTransactionsComplaintsList />}
              />
              <Route
                path={"/buyer/api-credentials"}
                element={<BuyerAPICredentials />}
              />
              <Route
                path={"/buyer/api-settings"}
                element={<BuyerAPISettings />}
              />
              <Route
                path={"/buyer/api-documentation"}
                element={<BuyerAPIDocumentation />}
              />
              <Route
                path={"/seller/api-settings"}
                element={<SellerAPISettingsList />}
              />
              <Route
                path={"/seller/api-settings/create"}
                element={<SellerAPISettingsCreate />}
              />
              <Route
                path={"/seller/api-settings/update/:id"}
                element={<SellerAPISettingsUpdate />}
              />
              <Route path={"/seller/margins"} element={<SellerMarginsList />} />
              <Route
                path={"/seller/margins/create"}
                element={<SellerMarginsCreate />}
              />
              <Route
                path={"/seller/margins/update/:id"}
                element={<SellerMarginsUpdate />}
              />
              <Route
                path={"/seller/transactions"}
                element={<SellerTransactionsList />}
              />
              <Route
                path={"/seller/transactions-pending"}
                element={<SellerTransactionsPendingList />}
              />
              <Route
                path={"/seller/transactions-complaints"}
                element={<SellerTransactionsComplaintsList />}
              />
              <Route
                path={"/reports/operator-daybook"}
                element={<OperatorDaybook />}
              />
              <Route path={"/reports/daybook"} element={<Daybook />} />
              <Route
                path={"/reports/buyer-wallet-transactions"}
                element={<BuyerWalletTransactions />}
              />
              <Route
                path={"/reports/seller-wallet-transactions"}
                element={<SellerWalletTransactions />}
              />
              <Route
                path={"/invoicing/buyer"}
                element={<BuyerInvoicesList />}
              />
              <Route
                path={"/invoicing/seller"}
                element={<SellerInvoicesList />}
              />
              <Route path={"/firm-details"} element={<FirmDetails />} />
            </Route>
            <Route element={<AuthRoutes />}>
              <Route path={"/register"} element={<Register />} />
              <Route path={"/login"} element={<Login />} />
              <Route path={"/forgot-password"} element={<ForgotPassword />} />
            </Route>
            <Route element={<KYCRoutes />}>
              <Route path={"/kyc"} element={<KYCPending />} />
            </Route>
            <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
            <Route
              path={"/terms-and-conditions"}
              element={<TermsAndConditions />}
            />
            <Route path={"/refund-policy"} element={<RefundPolicy />} />
            <Route path={"/contact"} element={<Contact />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
