import { memo } from "react";
import { StyledHeader } from "../styled";
import WalletSection from "../WalletSection/WalletSection";
import AccountSection from "../AccountSection/AccountSection";
import LogoutSection from "../LogoutSection/LogoutSection";
import Logo from "../Logo/Logo";
import styled from "styled-components";

const StyledLogoContainer = styled.section`
  flex: 1;
`;

const Header = memo(
  ({
    fullName,
    profilePhoto,
    onClickLogout,
    gstBuyerWallet,
    gstSellerWallet,
    nonGstBuyerWallet,
    nonGstSellerWallet,
  }) => {
    return (
      <StyledHeader>
        <StyledLogoContainer>
          <Logo
            style={
              window.location.href.includes("remitsarathi") ||
              window.location.href.includes("localhost")
                ? { width: "100px", height: "100%" }
                : { width: "150px" }
            }
          />
        </StyledLogoContainer>
        <WalletSection
          gstBuyerWallet={gstBuyerWallet}
          gstSellerWallet={gstSellerWallet}
          nonGstBuyerWallet={nonGstBuyerWallet}
          nonGstSellerWallet={nonGstSellerWallet}
        />
        <AccountSection fullName={fullName} profilePhoto={profilePhoto} />
        <LogoutSection onClickLogout={onClickLogout} />
      </StyledHeader>
    );
  }
);

export default Header;
