import { useState } from "react";
import { useQuery, useMutation, useIsMutating } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledActionItemButtons,
  StyledEditItemButton,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusPill,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Switch,
  MessageBox,
  CustomConfirmButton,
  Dropdown,
} from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getAuthData, getSearchParams } from "../../utils";

const SellerMarginsList = () => {
  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();
  const [filters, setFilters] = useState({
    fApisId: "",
    fOperatorsId: "",
    fCirclesId: "",
    fCategoriesId: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const readApis = async () => {
    try {
      const response = await API.get(`/seller/api-settings?fetchBalance=No`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/seller/margins?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fApisId=${appliedFilters.fApisId}&fOperatorsId=${appliedFilters.fOperatorsId}&fCirclesId=${appliedFilters.fCirclesId}&fCategoriesId=${appliedFilters.fCategoriesId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const updateData = async ({ value, newData }) => {
    try {
      newData.marginStatus = value === true ? `Active` : `Inactive`;
      const response = await API.put(
        `/seller/margins/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fApisId=${appliedFilters.fApisId}&fOperatorsId=${appliedFilters.fOperatorsId}&fCirclesId=${appliedFilters.fCirclesId}&fCategoriesId=${appliedFilters.fCategoriesId}`,
        newData
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const deleteData = async (id) => {
    try {
      const response = await API.delete(
        `/seller/margins/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fApisId=${appliedFilters.fApisId}&fOperatorsId=${appliedFilters.fOperatorsId}&fCirclesId=${appliedFilters.fCirclesId}&fCategoriesId=${appliedFilters.fCategoriesId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["seller_margins", paginationData, appliedFilters],
    queryFn: readData,
  });

  const reloadData = async () => await refetch();

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: apis } = useQuery({
    queryKey: ["apis"],
    queryFn: readApis,
  });

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const { mutateAsync: updateDataMutation } = useMutation({
    mutationFn: updateData,
    onError: (error) => console.error(error),
    onSuccess: () => {
      reloadData();
    },
  });

  const { mutateAsync: deleteDataMutation } = useMutation({
    mutationFn: deleteData,
    onError: (error) => console.error(error),
    onSuccess: () => {
      reloadData();
    },
  });

  const isMutation = useIsMutating();

  const filterData = async (e) => {
    e.preventDefault();
    setAppliedFilters(filters);
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFilters({
      fApisId: "",
      fOperatorsId: "",
      fCirclesId: "",
      fCategoriesId: "",
    });
    setAppliedFilters({
      fApisId: "",
      fOperatorsId: "",
      fCirclesId: "",
      fCategoriesId: "",
    });
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Margins"
      showAddButton={true}
      addLink="create"
      addTitle="Create Margin"
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const apisOptions = [
    {
      name: "-- SELECT API --",
      value: "",
    },
  ];
  if (!isEmpty(apis) && apis.length > 0) {
    apis?.forEach(({ apiName, id }) =>
      apisOptions?.push({ name: apiName, value: id })
    );
  }

  const operatorsOptions = [
    {
      name: "-- SELECT OPERATOR --",
      value: "",
    },
  ];
  !isEmpty(meta?.operators) &&
    meta?.operators?.forEach(({ operatorName, id }) =>
      operatorsOptions?.push({ name: operatorName, value: id })
    );

  const circlesOptions = [
    {
      name: "-- SELECT CIRCLE --",
      value: "",
    },
  ];
  !isEmpty(meta?.circles) &&
    meta?.circles?.forEach(({ circleName, id }) =>
      circlesOptions?.push({ name: circleName, value: id })
    );

  const categoriesOptions = [
    {
      name: "-- SELECT CATEGORY --",
      value: "",
    },
  ];
  !isEmpty(meta?.categories) &&
    meta?.categories?.forEach(({ category, id }) =>
      categoriesOptions?.push({ name: category, value: id })
    );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isMutation > 0 || isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <Dropdown
                value={filters.fApisId}
                onChange={(value) => handleFilterChange("fApisId", value)}
                placeholder="API"
                disabled={isLoading}
                options={apisOptions}
              />
              <Dropdown
                value={filters.fOperatorsId}
                onChange={(value) => handleFilterChange("fOperatorsId", value)}
                placeholder="Operator"
                disabled={isLoading}
                options={operatorsOptions}
              />
              <Dropdown
                value={filters.fCirclesId}
                onChange={(value) => handleFilterChange("fCirclesId", value)}
                placeholder="Circle"
                disabled={isLoading}
                options={circlesOptions}
              />
              <Dropdown
                value={filters.fCategoriesId}
                onChange={(value) => handleFilterChange("fCategoriesId", value)}
                placeholder="Category"
                disabled={isLoading}
                options={categoriesOptions}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "API",
                  dataSelector: "apiName",
                  dataType: "string",
                },
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                },
                {
                  title: "Circle",
                  dataSelector: "circleName",
                  dataType: "string",
                },
                {
                  title: "Category",
                  dataSelector: "category",
                  dataType: "string",
                },
                {
                  title: "Margin Type",
                  dataSelector: "marginType",
                  dataType: "string",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "Operator Code",
                  dataSelector: "operatorCode",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "string",
                },
                {
                  title: "Margin",
                  dataSelector: "margin",
                  dataType: "string",
                  cellrenderer: (value) => `${value}%`,
                },
                {
                  title: "Max Limit",
                  dataSelector: "maxLimit",
                  dataType: "string",
                },
                {
                  title: "Used Limit",
                  dataSelector: "usedLimit",
                  dataType: "string",
                },
                {
                  title: "Status",
                  dataSelector: "marginStatus",
                  dataType: "string",
                  canSort: false,
                  align: "center",
                  cellrenderer: (value, data) =>
                    !isEmpty(value) && (
                      <Switch
                        value={value === "Active"}
                        onChange={async (value) =>
                          await updateDataMutation({
                            value,
                            newData: { ...data },
                          })
                        }
                      />
                    ),
                },
                {
                  title: "Admin Status",
                  dataSelector: "adminStatus",
                  dataType: "string",
                  align: "center",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={value === "Active" ? "green" : "red"}
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "",
                  dataSelector: "id",
                  canSort: false,
                  isSticky: "Yes",
                  stickyAlign: "right",
                  isDummy: "Yes",
                  textAlign: "center",
                  width: "max-content",
                  cellrenderer: (value, item) => (
                    <StyledActionItemButtons>
                      <StyledEditItemButton
                        to={`update/${value}`}
                        title="Update Margin"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledEditItemButton>
                      <CustomConfirmButton
                        showTitle={false}
                        onClick={async () => await deleteDataMutation(value)}
                      />
                    </StyledActionItemButtons>
                  ),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(SellerMarginsList);
