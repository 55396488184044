import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, Dropdown, TextInput } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData } from "../../utils";

const Daybook = () => {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportType, setReportType] = useState("Buyer");
  const [marginType, setMarginType] = useState("GST");
  const [dataProfileType, setDataProfileType] = useState("");

  const readData = async () => {
    try {
      setDataProfileType(reportType);
      const response = await API.get(
        `/reports/daybook?reportType=${reportType}&fromDate=${fromDate}&toDate=${toDate}&marginType=${marginType}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["day_book"],
    queryFn: readData,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setDataProfileType("Buyer");
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
    setReportType("Buyer");
    setMarginType("GST");
    refetch();
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Daybook"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const reportTypeOptions = [
    {
      name: "-- SELECT REPORT TYPE --",
      value: "",
    },
    {
      name: "Buyer",
      value: "Buyer",
    },
    {
      name: "Seller",
      value: "Seller",
    },
  ];

  const marginTypeOptions = [
    {
      name: "-- SELECT MARGIN TYPE --",
      value: "",
    },
    {
      name: "GST",
      value: "GST",
    },
    {
      name: "Non-GST",
      value: "Non-GST",
    },
  ];

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <TextInput
                type="date"
                value={fromDate}
                onChange={setFromDate}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={toDate}
                onChange={setToDate}
                placeholder="To Date"
                disabled={isLoading}
              />
              <Dropdown
                value={reportType}
                onChange={setReportType}
                placeholder="Report Type"
                options={reportTypeOptions}
                disabled={isLoading}
              />
              {nonGstWalletStatus === "Active" && (
                <Dropdown
                  value={marginType}
                  onChange={setMarginType}
                  placeholder="Margin Type"
                  options={marginTypeOptions}
                  disabled={isLoading}
                />
              )}
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={
                dataProfileType === "Buyer"
                  ? [
                      {
                        title: "Date",
                        dataSelector: "reportDate",
                        dataType: "string",
                      },
                      {
                        title: "Opening Balance",
                        dataSelector: "openingBalance",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Purchase",
                        dataSelector: "purchase",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Recharge",
                        dataSelector: "recharge",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Commission",
                        dataSelector: "commission",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Refund",
                        dataSelector: "refund",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Closing Balance",
                        dataSelector: "closingBalance",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                    ]
                  : [
                      {
                        title: "Date",
                        dataSelector: "reportDate",
                        dataType: "string",
                      },
                      {
                        title: "Opening Balance",
                        dataSelector: "openingBalance",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Recharge",
                        dataSelector: "recharge",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Commission",
                        dataSelector: "commission",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Refund",
                        dataSelector: "refund",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Withdraw",
                        dataSelector: "withdraw",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                      {
                        title: "Closing Balance",
                        dataSelector: "closingBalance",
                        dataType: "string",
                        cellrenderer: (value) => formatCurrency(value),
                      },
                    ]
              }
              tableData={data?.data}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(Daybook);
