import { memo } from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  width: 100%;
`;

const Logo = memo((props) => (
  <StyledImage
    src={
      window.location.href.includes("remitsarathi") ||
      window.location.href.includes("localhost")
        ? "/remitsarathi.png"
        : "/logo.png"
    }
    alt="API Exchange"
    {...props}
  />
));

export default Logo;
