import { useState } from "react";
import { useQuery, useMutation, useIsMutating } from "@tanstack/react-query";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledActionItemButtons,
  StyledStatusPill,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, CustomConfirmButton } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getSearchParams } from "../../utils";

const BankAccountsList = () => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const readData = async () => {
    try {
      const response = await API.get(
        `/payments/bank-accounts?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const deleteData = async (id) => {
    try {
      const response = await API.delete(
        `/payments/bank-accounts/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["bank_accounts", paginationData],
    queryFn: readData,
  });

  const reloadData = async () => await refetch();

  const { mutateAsync: deleteDataMutation } = useMutation({
    mutationFn: deleteData,
    onError: (error) => console.error(error),
    onSuccess: () => {
      reloadData();
    },
  });

  const isMutation = useIsMutating();

  const renderPageHeading = () => (
    <PageHeader
      title="Bank Accounts List"
      showAddButton={true}
      addLink="create"
      addTitle="Add Bank Account"
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  return (
    <>
      <Loader isLoading={isMutation > 0 || isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Bank Name",
                  dataSelector: "bankName",
                  dataType: "string",
                },
                {
                  title: "Payee Name",
                  dataSelector: "payeeName",
                  dataType: "string",
                },
                {
                  title: "Account Number",
                  dataSelector: "accountNumber",
                  dataType: "string",
                },
                {
                  title: "IFSC Code",
                  dataSelector: "ifscCode",
                  dataType: "string",
                },
                {
                  title: "Status",
                  dataSelector: "approvalStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === "Approved"
                          ? "green"
                          : value === "Rejected"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Created",
                  dataSelector: "createdOn",
                  dataType: "string",
                },
                {
                  title: "",
                  dataSelector: "id",
                  canSort: false,
                  isSticky: "Yes",
                  stickyAlign: "right",
                  isDummy: "Yes",
                  textAlign: "center",
                  width: "max-content",
                  cellrenderer: (value, item) =>
                    item.approvalStatus !== "Approved" && (
                      <StyledActionItemButtons>
                        <CustomConfirmButton
                          showTitle={false}
                          onClick={async () => await deleteDataMutation(value)}
                        />
                      </StyledActionItemButtons>
                    ),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(BankAccountsList);
