import { useState } from "react";
import { useQuery, useMutation, useIsMutating } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faFlag,
  faSearch,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import styled from "styled-components";
import JSONPretty from "react-json-pretty";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledActionItemButtons,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusPill,
  StyledModalContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  MessageBox,
  Dropdown,
  TextInput,
  CustomConfirmButton,
  CustomButton,
} from "../../components/FormElements";
import Modal from "../../components/Modal";
import Accordination from "../../components/Accordination/Accordination";
import { Grid, GridItem } from "../../components/Grid";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getSearchParams } from "../../utils";

const StyledBodyContainer = styled.section`
  font-size: 14px;
  font-weight: normal;
  color: #000;
  word-break: break-all;
  padding: 0px;
  line-height: 20px;
  border: 1px solid #e5e5e5;
  margin: 20px;
  border-radius: 5px;
`;

const StyledHeading = styled.h2`
  font-size: 16px;
  font-weight: normal;
  color: #000;
  background-color: #e5e5e5;
  padding: 15px;
  margin: 0px;
`;

const StyledContent = styled.section`
  padding: 20px;
  @media (max-width: 600px) {
    word-break: break-all;
    overflow-x: scroll;
  }
`;

const StyledApiCallLogsContainer = styled.section`
  width: auto;
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
  padding: 20px;
  pre {
    white-space: pre-wrap;
  }
`;

const BuyerTransactionsList = () => {
  const [filters, setFilters] = useState({
    fOperatorsId: "",
    fCirclesId: "",
    fCategoriesId: "",
    fRechargeStatus: "",
    fRechargeNumber: "",
    fFromDate: "",
    fToDate: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [isLogsLoading, setIsLogsLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [logs, setLogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/buyer/transactions?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fOperatorsId=${appliedFilters.fOperatorsId}&fCirclesId=${appliedFilters.fCirclesId}&fCategoriesId=${appliedFilters.fCategoriesId}&fRechargeStatus=${appliedFilters.fRechargeStatus}&fRechargeNumber=${appliedFilters.fRechargeNumber}&fFromDate=${appliedFilters.fFromDate}&fToDate=${appliedFilters.fToDate}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const raiseComplaint = async ({ id, complaintBuyerRemark }) => {
    try {
      const response = await API.post(`/buyer/transactions/complaint`, {
        id,
        remark: complaintBuyerRemark,
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const readApiLogs = async (id) => {
    setIsLogsLoading(true);
    try {
      const response = await API.get(`/buyer/transactions/logs/${id}`);
      if (response.data.status === RESPONSE_STATUSES.SUCCESS) {
        setLogs(response?.data?.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLogsLoading(false);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["transactions", paginationData, appliedFilters],
    queryFn: readData,
  });

  const reloadData = async () => await refetch();

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const { mutateAsync: raiseComplaintMutation } = useMutation({
    mutationFn: raiseComplaint,
    onError: (error) => console.error(error),
    onSuccess: () => {
      reloadData();
    },
  });

  const isMutation = useIsMutating();

  const filterData = async (e) => {
    e.preventDefault();
    setAppliedFilters(filters);
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFilters({
      fOperatorsId: "",
      fCirclesId: "",
      fCategoriesId: "",
      fRechargeStatus: "",
      fRechargeNumber: "",
      fFromDate: "",
      fToDate: "",
    });
    setAppliedFilters({
      fOperatorsId: "",
      fCirclesId: "",
      fCategoriesId: "",
      fRechargeStatus: "",
      fRechargeNumber: "",
      fFromDate: "",
      fToDate: "",
    });
  };

  const toggleApiLogs = async (id) => {
    setModalType("API_LOGS");
    setShowModal(true);
    await readApiLogs(id);
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Transactions"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const operatorsOptions = [
    {
      name: "OPERATOR",
      value: "",
    },
  ];
  !isEmpty(meta?.operators) &&
    meta?.operators?.forEach(({ operatorName, id }) =>
      operatorsOptions?.push({ name: operatorName, value: id })
    );

  const circlesOptions = [
    {
      name: "CIRCLE",
      value: "",
    },
  ];
  !isEmpty(meta?.circles) &&
    meta?.circles?.forEach(({ circleName, id }) =>
      circlesOptions?.push({ name: circleName, value: id })
    );

  const categoriesOptions = [
    {
      name: "CATEGORY",
      value: "",
    },
  ];
  !isEmpty(meta?.categories) &&
    meta?.categories?.forEach(({ category, id }) =>
      categoriesOptions?.push({ name: category, value: id })
    );

  const rechargeStatusOptions = [
    {
      name: "STATUS",
      value: "",
    },
    {
      name: "Pending",
      value: "Pending",
    },
    {
      name: "Success",
      value: "Success",
    },
    {
      name: "Failed",
      value: "Failed",
    },
  ];

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  return (
    <>
      <Loader isLoading={isMutation > 0 || isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <Dropdown
                value={filters.fOperatorsId}
                onChange={(value) => handleFilterChange("fOperatorsId", value)}
                placeholder="Operator"
                disabled={isLoading}
                options={operatorsOptions}
              />
              <Dropdown
                value={filters.fCirclesId}
                onChange={(value) => handleFilterChange("fCirclesId", value)}
                placeholder="Circle"
                disabled={isLoading}
                options={circlesOptions}
              />
              <Dropdown
                value={filters.fCategoriesId}
                onChange={(value) => handleFilterChange("fCategoriesId", value)}
                placeholder="Category"
                disabled={isLoading}
                options={categoriesOptions}
              />
              <Dropdown
                value={filters.fRechargeStatus}
                onChange={(value) =>
                  handleFilterChange("fRechargeStatus", value)
                }
                placeholder="Status"
                disabled={isLoading}
                options={rechargeStatusOptions}
              />
              <TextInput
                value={filters.fRechargeNumber}
                onChange={(value) =>
                  handleFilterChange("fRechargeNumber", value)
                }
                placeholder="Recharge Number"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={filters.fFromDate}
                onChange={(value) => handleFilterChange("fFromDate", value)}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={filters.fToDate}
                onChange={(value) => handleFilterChange("fToDate", value)}
                placeholder="To Date"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                },
                {
                  title: "Recharge Number",
                  dataSelector: "rechargeNumber",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "rechargeAmount",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Margin",
                  dataSelector: "buyersMargin",
                  dataType: "string",
                  cellrenderer: (value) => `${value}%`,
                },
                {
                  title: "Status",
                  dataSelector: "rechargeStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === "Success"
                          ? "green"
                          : value === "Failed"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Operators Id",
                  dataSelector: "rechargeOperatorsId",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Category",
                  dataSelector: "category",
                  dataType: "string",
                },
                {
                  title: "Transaction Type",
                  dataSelector: "transactionType",
                  dataType: "string",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "Circle",
                  dataSelector: "circleName",
                  dataType: "string",
                },
                {
                  title: "Transaction Time",
                  dataSelector: "createdOn",
                  dataType: "string",
                },
                {
                  title: "",
                  dataSelector: "id",
                  canSort: false,
                  isSticky: "Yes",
                  stickyAlign: "right",
                  isDummy: "Yes",
                  textAlign: "center",
                  width: "max-content",
                  cellrenderer: (value, item) => (
                    <StyledActionItemButtons>
                      {item.rechargeStatus === "Success" &&
                        item.complaintResolutionStatus !== "Pending" &&
                        item.complaintReopened !== "Yes" && (
                          <CustomConfirmButton
                            showIcon={true}
                            showTitle={false}
                            isBuyerComplaint={true}
                            icon={faFlag}
                            title="Raise Complaint"
                            proceedButtonText="Confirm"
                            message="Are you sure, you want to raise complaint ?"
                            onClick={async (state) => {
                              await raiseComplaintMutation({
                                id: value,
                                ...state,
                              });
                            }}
                          />
                        )}
                      <CustomButton
                        icon={faEye}
                        onClick={() => toggleApiLogs(value)}
                        style={{ color: "orange" }}
                      />
                    </StyledActionItemButtons>
                  ),
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
            <Modal
              modalStatus={showModal}
              setModalStatus={() => {
                setShowModal(false);
                setModalType("");
                setLogs([]);
              }}
              hideCloseButton
            >
              <StyledModalContainer
                width={modalType === "API_LOGS" ? `1200px` : `500px`}
              >
                <h3>{modalType === "API_LOGS" ? `API Call Logs` : ``}</h3>
                {modalType === "API_LOGS" && (
                  <StyledApiCallLogsContainer>
                    {isLogsLoading && <LoadingSpinner />}
                    {!isLogsLoading && logs.length === 0 && (
                      <NoData message={`No logs found`} />
                    )}
                    {logs?.length > 0 &&
                      logs.map(
                        (
                          {
                            requestType,
                            request,
                            requestOn,
                            requestBody,
                            response,
                            responseOn,
                          },
                          index
                        ) => (
                          <Accordination
                            key={index}
                            header={`${requestType}`}
                            autoExpanded={true}
                          >
                            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                              <GridItem>
                                <StyledBodyContainer>
                                  <StyledHeading>
                                    API Request{" "}
                                    {requestOn && `( ${requestOn} )`}
                                  </StyledHeading>
                                  <StyledContent>{request}</StyledContent>
                                </StyledBodyContainer>
                                {requestBody && (
                                  <StyledBodyContainer>
                                    <StyledHeading>
                                      API Request Body
                                    </StyledHeading>
                                    <StyledContent>{requestBody}</StyledContent>
                                  </StyledBodyContainer>
                                )}
                              </GridItem>
                              <GridItem>
                                <StyledBodyContainer>
                                  <StyledHeading>
                                    API Response{" "}
                                    {responseOn && `( ${responseOn} )`}
                                  </StyledHeading>
                                  <StyledContent>
                                    <JSONPretty
                                      id="json-pretty"
                                      data={response}
                                    ></JSONPretty>
                                  </StyledContent>
                                </StyledBodyContainer>
                              </GridItem>
                            </Grid>
                          </Accordination>
                        )
                      )}
                  </StyledApiCallLogsContainer>
                )}
              </StyledModalContainer>
            </Modal>
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(BuyerTransactionsList);
