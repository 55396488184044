import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faIndianRupeeSign,
  faDownLong,
  faUpLong,
  faPieChart,
  faFileInvoice,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";

const StyledNavigation = styled.nav`
  width: 100%;
  height: auto;
  background-color: ${({
    theme: {
      colors: { primaryColor },
    },
  }) => (primaryColor ? primaryColor : `#0095ff`)};
  margin: 0px;
  padding: 0px;
  display: block;
  @media (max-width: 600px) {
    display: none;
    &.show {
      display: block;
    }
  }
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      display: inline-block;
      margin: 0px;
      padding: 0px;
      position: relative;
      transition: background-color 0.5s;
      border-right: 1px solid
        ${({
          theme: {
            colors: { secondaryColor },
          },
        }) => (secondaryColor ? secondaryColor : `#0078ce`)};

      @media (max-width: 600px) {
        display: block;
      }
      &:hover {
        background-color: ${({
          theme: {
            colors: { secondaryColor },
          },
        }) => (secondaryColor ? secondaryColor : `#0078ce`)};
        @media (max-width: 600px) {
          background-color: transparent;
        }
      }
      &:hover > ul {
        display: block;
      }
      a {
        font-size: 14px;
        font-weight: normal;
        color: #ffffff;
        margin: 0px;
        padding: 12px 20px;
        text-decoration: none;
        display: block;
        &.active {
          background-color: ${({
            theme: {
              colors: { secondaryColor },
            },
          }) => (secondaryColor ? secondaryColor : `#0078ce`)};
          @media (max-width: 600px) {
            background-color: transparent;
          }
        }
      }
      ul {
        position: absolute;
        left: -1px;
        display: none;
        list-style: none;
        width: auto;
        min-width: 300px;
        height: auto;
        margin: 0px;
        padding: 0px;
        background-color: ${({
          theme: {
            colors: { secondaryColor },
          },
        }) => (secondaryColor ? secondaryColor : `#0078ce`)};
        z-index: 999999;
        @media (max-width: 600px) {
          display: block;
          position: static;
        }
        li {
          display: block;
          margin: 0px;
          padding: 0px;
          border-right: 0px;
          border-bottom: 1px solid
            ${({
              theme: {
                colors: { tertiaryColor },
              },
            }) => (tertiaryColor ? tertiaryColor : `#0067b0`)};
          &:hover {
            background-color: ${({
              theme: {
                colors: { tertiaryColor },
              },
            }) => (tertiaryColor ? tertiaryColor : `#0067b0`)};
          }
          a {
            font-size: 14px;
            font-weight: normal;
            color: #ffffff;
            margin: 0px;
            padding: 12px 15px;
            text-decoration: none;
            display: block;
            transition: background-color 0.5s;
            @media (max-width: 600px) {
              padding-left: 40px;
            }
            &.active {
              background-color: ${({
                theme: {
                  colors: { tertiaryColor },
                },
              }) => (tertiaryColor ? tertiaryColor : `#0067b0`)};
            }
          }
        }
      }
    }
  }
`;

const Navigation = ({ showNav }) => {
  const onClickDummyLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledNavigation className={showNav ? "show" : ""}>
      <ul>
        <li>
          <NavLink to="/" title="Dashboard">
            <FontAwesomeIcon icon={faHome} /> Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to="/payments" title="Payments" onClick={onClickDummyLink}>
            <FontAwesomeIcon icon={faIndianRupeeSign} /> Payments
          </NavLink>
          <ul>
            <li>
              <NavLink
                to="/payments/wallet-topups"
                title="Wallet Topups"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Wallet Topups
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/payments/bank-accounts"
                title="Bank Accounts"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Bank Accounts
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/payments/withdraw-funds"
                title="Withdraw Funds"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Withdraw Funds
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/payments/switch-funds"
                title="Switch Funds"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Switch Funds
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/buyer" title="Buyer" onClick={onClickDummyLink}>
            <FontAwesomeIcon icon={faDownLong} /> Buyer
          </NavLink>
          <ul>
            <li>
              <NavLink
                to="/buyer/available-margins"
                title="Available Margins"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Available Margins
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buyer/margins"
                title="Margins"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Margins
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buyer/transactions"
                title="Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buyer/transactions-pending"
                title="Pending Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Pending Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buyer/transactions-complaints"
                title="Complaint Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Complaint Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buyer/api-credentials"
                title="API Credentials"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                API Credentials
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buyer/api-settings"
                title="API Settings"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                API Settings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buyer/api-documentation"
                title="API Documentation"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                API Documentation
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/seller" title="Seller" onClick={onClickDummyLink}>
            <FontAwesomeIcon icon={faUpLong} /> Seller
          </NavLink>
          <ul>
            <li>
              <NavLink
                to="/seller/api-settings"
                title="API Settings"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                API Settings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/seller/margins"
                title="Margins"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Margins
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/seller/transactions"
                title="Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/seller/transactions-pending"
                title="Pending Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Pending Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/seller/transactions-complaints"
                title="Complaint Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Complaint Transactions
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/reports" title="Reports" onClick={onClickDummyLink}>
            <FontAwesomeIcon icon={faPieChart} /> Reports
          </NavLink>
          <ul>
            <li>
              <NavLink
                to="/reports/operator-daybook"
                title="Operator Daybook"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Operator Daybook
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reports/daybook"
                title="Daybook"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Daybook
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reports/buyer-wallet-transactions"
                title="Buyer Wallet Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Buyer Wallet Transactions
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reports/seller-wallet-transactions"
                title="Seller Wallet Transactions"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Seller Wallet Transactions
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/invoicing" title="Invoicing" onClick={onClickDummyLink}>
            <FontAwesomeIcon icon={faFileInvoice} /> Invoicing
          </NavLink>
          <ul>
            <li>
              <NavLink
                to="/invoicing/buyer"
                title="Buyer Invoices"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Buyer Invoices
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/invoicing/seller"
                title="Seller Invoices"
                className={({ isActive }) => (isActive ? `active` : ``)}
              >
                Seller Invoices
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/firm-details" title="Support & Firm Details">
            <FontAwesomeIcon icon={faHeadphones} /> Support & Firm Details
          </NavLink>
        </li>
      </ul>
    </StyledNavigation>
  );
};

export default Navigation;
