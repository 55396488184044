import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import exportFromJSON from "export-from-json";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
  StyledStatusPill,
  StyledIconedText,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  MessageBox,
  SearchInput,
  TextInput,
} from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import Pagination from "../../components/Pagination/Pagination";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData, getSearchParams } from "../../utils";
import { Image } from "../../components/Image";

const WalletTopupsList = () => {
  const [filters, setFilters] = useState({
    searchQuery: "",
    fromDate: "",
    toDate: "",
  });
  const [appliedFilters, setAppliedFilters] = useState(filters);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 30,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/payments/wallet-topups?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${appliedFilters.searchQuery}&fFromDate=${appliedFilters.fromDate}&fToDate=${appliedFilters.toDate}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["wallet_topups", paginationData, appliedFilters],
    queryFn: readData,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    setAppliedFilters(filters);
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFilters({
      searchQuery: "",
      fromDate: "",
      toDate: "",
    });
    setAppliedFilters({
      searchQuery: "",
      fromDate: "",
      toDate: "",
    });
  };

  const exportData = async () => {
    exportFromJSON({
      data: data.data,
      fileName: "wallet_topups",
      exportType: exportFromJSON.types.csv,
    });
  };

  const renderPageHeading = () => (
    <PageHeader
      title="Wallet Topup History"
      showAddButton={true}
      addLink="create"
      addTitle="Topup Wallet"
      showReloadData={true}
      reloadData={reloadData}
      showExportButton={true}
      onClickExport={exportData}
    />
  );

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <SearchInput
                value={filters.searchQuery}
                onChange={(value) => handleFilterChange("searchQuery", value)}
                disabled={isLoading}
                placeholder="Search UTR Number"
              />
              <TextInput
                type="date"
                value={filters.fromDate}
                onChange={(value) => handleFilterChange("fromDate", value)}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={filters.toDate}
                onChange={(value) => handleFilterChange("toDate", value)}
                placeholder="To Date"
                disabled={isLoading}
              />
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Bank Details",
                  dataSelector: "bankName",
                  dataType: "string",
                  cellrenderer: (value, item) => (
                    <StyledIconedText>
                      <Image
                        source={item.bankLogo || "no-image.png"}
                        alt={value}
                        style={{ width: "30px", height: "30px" }}
                      />
                      {value}
                    </StyledIconedText>
                  ),
                },
                {
                  title: "Payee Name",
                  dataSelector: "payeeName",
                  dataType: "string",
                },
                {
                  title: "Account No",
                  dataSelector: "accountNumber",
                  dataType: "string",
                },
                {
                  title: "IFSC Code",
                  dataSelector: "ifscCode",
                  dataType: "string",
                },
                {
                  title: "Payment Type",
                  dataSelector: "paymentType",
                  dataType: "string",
                },
                {
                  title: "Wallet Type",
                  dataSelector: "walletType",
                  dataType: "string",
                  shouldShow: nonGstWalletStatus === "Active",
                },
                {
                  title: "UTR Number",
                  dataSelector: "utrNumber",
                  dataType: "string",
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Status",
                  dataSelector: "approvalStatus",
                  dataType: "string",
                  cellrenderer: (value) => (
                    <StyledStatusPill
                      className={
                        value === "Approved"
                          ? "green"
                          : value === "Rejected"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StyledStatusPill>
                  ),
                },
                {
                  title: "Remark",
                  dataSelector: "remark",
                  dataType: "string",
                  cellrenderer: (value) => value || "N/A",
                },
                {
                  title: "Created",
                  dataSelector: "createdOn",
                  dataType: "string",
                },
                {
                  title: "Processed",
                  dataSelector: "updatedOn",
                  dataType: "string",
                },
              ]}
              tableData={data?.data}
            />
            <Pagination
              totalRecords={data?.totalRecords}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(WalletTopupsList);
