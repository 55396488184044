import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledMainAreaContainer,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  Button,
} from "../../components/FormElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { getAuthData, getReturnUrl } from "../../utils";

const BuyerMarginsCreate = () => {
  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();
  const [state, setState] = useState({
    isLoading: false,
    responseStatus: "",
    responseMessage: "",
    operatorsId: "",
    circlesId: "",
    categoriesId: "",
    marginType: "GST",
    fromAmount: "",
    toAmount: "",
    margin: "",
    marginStatus: "Active",
    disableCategory: false,
  });

  const readMeta = async () => {
    try {
      const response = await API.get(`/meta`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data: meta } = useQuery({
    queryKey: ["meta"],
    queryFn: readMeta,
  });

  const operatorsOptions = [
    {
      name: "-- SELECT OPERATOR --",
      value: "",
    },
  ];
  !isEmpty(meta?.operators) &&
    meta?.operators
      ?.filter(({ activeForBuyer }) => activeForBuyer === "Active")
      ?.forEach(({ operatorName, id }) =>
        operatorsOptions?.push({ name: operatorName, value: id })
      );

  const circlesOptions = [
    {
      name: "-- SELECT CIRCLE --",
      value: "",
    },
  ];
  !isEmpty(meta?.circles) &&
    meta?.circles?.forEach(({ circleName, id }) =>
      circlesOptions?.push({ name: circleName, value: id })
    );

  const categoriesOptions = [
    {
      name: "-- SELECT CATEGORY --",
      value: "",
    },
  ];
  !isEmpty(meta?.categories) &&
    meta?.categories?.forEach(({ category, id }) =>
      categoriesOptions?.push({ name: category, value: id })
    );

  const marginTypeOptions = [
    {
      name: "-- SELECT MARGIN TYPE --",
      value: "",
    },
    {
      name: "GST",
      value: "GST",
    },
    {
      name: "Non-GST",
      value: "Non-GST",
    },
  ];

  const marginStatuOptions = [
    {
      name: "-- SELECT MARGIN STATUS --",
      value: "",
    },
    {
      name: "Active",
      value: "Active",
    },
    {
      name: "Inactive",
      value: "Inactive",
    },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      responseStatus: "",
      responseMessage: "",
    }));
    try {
      const response = await API.post("/buyer/margins", {
        operatorsId: state.operatorsId,
        circlesId: state.circlesId,
        categoriesId: state.categoriesId,
        marginType: state.marginType,
        fromAmount: state.fromAmount,
        toAmount: state.toAmount,
        margin: state.margin,
        marginStatus: state.marginStatus,
      });
      const { status, message } = response.data;
      if (status === RESPONSE_STATUSES.SUCCESS) {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.SUCCESS,
          responseMessage: message,
          operatorsId: "",
          circlesId: "",
          categoriesId: "",
          marginType: "",
          fromAmount: "",
          toAmount: "",
          margin: "",
          marginStatus: "",
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            responseStatus: "",
            responseMessage: "",
          }));
          window.location.href = getReturnUrl("create");
        }, 3000);
      } else {
        setState((prevState) => ({
          ...prevState,
          responseStatus: RESPONSE_STATUSES.FAILED,
          responseMessage: message,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        responseStatus: RESPONSE_STATUSES.FAILED,
        responseMessage: error.message,
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const selectCategory = useCallback(() => {
    const operator = meta?.operators?.find(
      (item) => item.id === parseInt(state.operatorsId)
    );
    if (operator?.rofferApplicable === "No") {
      setState((prevState) => ({
        ...prevState,
        categoriesId: 1,
        disableCategory: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        categoriesId: "",
        disableCategory: false,
      }));
    }
  }, [state.operatorsId, meta]);

  useEffect(() => {
    selectCategory();
  }, [state.operatorsId, selectCategory]);

  const renderPageHeading = () => (
    <PageHeader title="Create Margin" showBackButton={true} />
  );

  return (
    <>
      {renderPageHeading()}
      <StyledContentContainer>
        <StyledMainAreaContainer>
          <Form
            action="#"
            method="POST"
            onSubmit={onSubmit}
            style={{ maxWidth: "500px" }}
          >
            <Fieldset>
              <Label required>Operator</Label>
              <Dropdown
                value={state.operatorsId}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    operatorsId: value,
                  }))
                }
                placeholder="Operator"
                disabled={state.isLoading}
                options={operatorsOptions}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Circle</Label>
              <Dropdown
                value={state.circlesId}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    circlesId: value,
                  }))
                }
                placeholder="Circle"
                disabled={state.isLoading}
                options={circlesOptions}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Category</Label>
              <Dropdown
                value={state.categoriesId}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    categoriesId: value,
                  }))
                }
                placeholder="Category"
                disabled={state.disableCategory || state.isLoading}
                options={categoriesOptions}
              />
            </Fieldset>
            {nonGstWalletStatus === "Active" && (
              <Fieldset>
                <Label required>Margin Type</Label>
                <Dropdown
                  value={state.marginType}
                  onChange={(value) =>
                    setState((prevState) => ({
                      ...prevState,
                      marginType: value,
                    }))
                  }
                  placeholder="Margin Type"
                  disabled={state.isLoading}
                  options={marginTypeOptions}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label required>From Amount</Label>
              <TextInput
                value={state.fromAmount}
                isNumeric={true}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    fromAmount: value,
                  }))
                }
                placeholder="From Amount"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>To Amount</Label>
              <TextInput
                value={state.toAmount}
                isNumeric={true}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    toAmount: value,
                  }))
                }
                placeholder="To Amount"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Margin</Label>
              <TextInput
                value={state.margin}
                isNumeric={true}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    margin: value,
                  }))
                }
                placeholder="Margin"
                disabled={state.isLoading}
              />
            </Fieldset>
            <Fieldset>
              <Label required>Margin Status</Label>
              <Dropdown
                value={state.marginStatus}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    marginStatus: value,
                  }))
                }
                placeholder="Margin Status"
                disabled={state.isLoading}
                options={marginStatuOptions}
              />
            </Fieldset>
            {state.responseStatus && state.responseMessage && (
              <Fieldset>
                <MessageBox
                  status={state.responseStatus}
                  message={state.responseMessage}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={state.isLoading}>Create</Button>
            </Fieldset>
          </Form>
        </StyledMainAreaContainer>
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(BuyerMarginsCreate);
