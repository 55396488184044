import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { withAppHOC } from "../../hoc";
import {
  StyledContentContainer,
  StyledFiltersContainer,
  StyledFilters,
  StyledFilterContainer,
  StyledSearchButton,
  StyledResetButton,
} from "../../components/styled";
import PageHeader from "../../components/PageHeader/PageHeader";
import { MessageBox, Dropdown, TextInput } from "../../components/FormElements";
import Loader from "../../components/Loader/Loader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import { TableBuilder } from "../../components/TableElements";
import API from "../../api";
import { RESPONSE_STATUSES } from "../../constants";
import { formatCurrency, getAuthData } from "../../utils";
import { isEmpty } from "lodash";

const OperatorDaybook = () => {
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportType, setReportType] = useState("Buyer");
  const [marginType, setMarginType] = useState("GST");
  const [apisId, setApisId] = useState("");

  const readApis = async () => {
    try {
      const response = await API.get(`/seller/api-settings?fetchBalance=No`);
      return response?.data?.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const readData = async () => {
    try {
      const response = await API.get(
        `/reports/operator-daybook?reportType=${reportType}&fromDate=${fromDate}&toDate=${toDate}&marginType=${marginType}&apisId=${apisId}`
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  const { data, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: ["operator_day_book"],
    queryFn: readData,
  });

  const { data: apis } = useQuery({
    queryKey: ["apis"],
    queryFn: readApis,
  });

  const reloadData = async () => await refetch();

  const filterData = async (e) => {
    e.preventDefault();
    await refetch();
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
    setReportType("Buyer");
    setMarginType("GST");
    refetch();
  };

  const apisOptions = [
    {
      name: "-- SELECT API --",
      value: "",
    },
  ];
  if (!isEmpty(apis) && apis.length > 0) {
    apis?.forEach(({ apiName, id }) =>
      apisOptions?.push({ name: apiName, value: id })
    );
  }

  const renderPageHeading = () => (
    <PageHeader
      title="Operator Daybook"
      showAddButton={false}
      showReloadData={true}
      reloadData={reloadData}
    />
  );

  const reportTypeOptions = [
    {
      name: "-- SELECT REPORT TYPE --",
      value: "",
    },
    {
      name: "Buyer",
      value: "Buyer",
    },
    {
      name: "Seller",
      value: "Seller",
    },
  ];

  const marginTypeOptions = [
    {
      name: "-- SELECT MARGIN TYPE --",
      value: "",
    },
    {
      name: "GST",
      value: "GST",
    },
    {
      name: "Non-GST",
      value: "Non-GST",
    },
  ];

  if (isLoading) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <LoadingSpinner />
        </StyledContentContainer>
      </>
    );
  }

  if (error) {
    return (
      <>
        {renderPageHeading()}
        <StyledContentContainer>
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={error.message}
          />
        </StyledContentContainer>
      </>
    );
  }

  const {
    userProfile: { nonGstWalletStatus },
  } = getAuthData();

  return (
    <>
      <Loader isLoading={isRefetching} />
      {renderPageHeading()}
      <StyledContentContainer graybackground="true">
        <StyledFiltersContainer>
          <StyledFilters>
            <StyledFilterContainer>
              <TextInput
                type="date"
                value={fromDate}
                onChange={setFromDate}
                placeholder="From Date"
                disabled={isLoading}
              />
              <TextInput
                type="date"
                value={toDate}
                onChange={setToDate}
                placeholder="To Date"
                disabled={isLoading}
              />
              <Dropdown
                value={reportType}
                onChange={setReportType}
                placeholder="Report Type"
                options={reportTypeOptions}
                disabled={isLoading}
              />
              {nonGstWalletStatus === "Active" && (
                <Dropdown
                  value={marginType}
                  onChange={setMarginType}
                  placeholder="Margin Type"
                  options={marginTypeOptions}
                  disabled={isLoading}
                />
              )}
              {reportType === "Seller" && (
                <Dropdown
                  value={apisId}
                  onChange={setApisId}
                  placeholder="API"
                  options={apisOptions}
                  disabled={isLoading}
                />
              )}
            </StyledFilterContainer>
            <StyledSearchButton onClick={filterData} type="button">
              <FontAwesomeIcon icon={faSearch} />
              Search
            </StyledSearchButton>
            <StyledResetButton onClick={clearFilters} type="button">
              <FontAwesomeIcon icon={faX} />
              Clear
            </StyledResetButton>
          </StyledFilters>
        </StyledFiltersContainer>
        {data?.status === RESPONSE_STATUSES.FAILED && (
          <MessageBox
            status={RESPONSE_STATUSES.FAILED}
            message={data?.message}
          />
        )}
        {data?.data?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <TableBuilder
              isLoading={isLoading}
              tableHeadings={[
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                },
                {
                  title: "Success",
                  dataSelector: "successAmount",
                  dataType: "string",
                  cellrenderer: (value, item) =>
                    `${formatCurrency(value)} (${item.successCount})`,
                },
                {
                  title: "Failed",
                  dataSelector: "failedAmount",
                  dataType: "string",
                  cellrenderer: (value, item) =>
                    `${formatCurrency(value)} (${item.failedCount})`,
                },
                {
                  title: "Pending",
                  dataSelector: "pendingAmount",
                  dataType: "string",
                  cellrenderer: (value, item) =>
                    `${formatCurrency(value)} (${item.pendingCount})`,
                },
                {
                  title: "Refunded",
                  dataSelector: "refundAmount",
                  dataType: "string",
                  cellrenderer: (value, item) =>
                    `${formatCurrency(value)} (${item.refundCount})`,
                },
                {
                  title: "Avg Margin",
                  dataSelector: "averageMargin",
                  dataType: "string",
                },
                {
                  title: "Commission",
                  dataSelector: "commission",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Wallet Credited",
                  dataSelector: "walletCredited",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Wallet Debited",
                  dataSelector: "walletDebited",
                  dataType: "string",
                  cellrenderer: (value) => formatCurrency(value),
                },
              ]}
              tableData={data?.data}
              tableFooter={[
                {
                  value: "Total",
                },
                {
                  value: `${formatCurrency(
                    data?.data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.successAmount),
                      0
                    )
                  )} (${data?.data.reduce(
                    (previousValue, currentValue) =>
                      previousValue + Number(currentValue.successCount),
                    0
                  )})`,
                },
                {
                  value: `${formatCurrency(
                    data?.data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.failedAmount),
                      0
                    )
                  )} (${data?.data.reduce(
                    (previousValue, currentValue) =>
                      previousValue + Number(currentValue.failedCount),
                    0
                  )})`,
                },
                {
                  value: `${formatCurrency(
                    data?.data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.pendingAmount),
                      0
                    )
                  )} (${data?.data.reduce(
                    (previousValue, currentValue) =>
                      previousValue + Number(currentValue.pendingCount),
                    0
                  )})`,
                },
                {
                  value: `${formatCurrency(
                    data?.data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.refundAmount),
                      0
                    )
                  )} (${data?.data.reduce(
                    (previousValue, currentValue) =>
                      previousValue + Number(currentValue.refundCount),
                    0
                  )})`,
                },
                {
                  value: "",
                },
                {
                  value: formatCurrency(
                    data?.data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.commission),
                      0
                    )
                  ),
                },
                {
                  value: formatCurrency(
                    data?.data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.walletCredited),
                      0
                    )
                  ),
                },
                {
                  value: formatCurrency(
                    data?.data.reduce(
                      (previousValue, currentValue) =>
                        previousValue + Number(currentValue.walletDebited),
                      0
                    )
                  ),
                },
              ]}
            />
          </>
        )}
      </StyledContentContainer>
    </>
  );
};

export default withAppHOC(OperatorDaybook);
